import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Load the saved mode from localStorage when the app initializes
    return localStorage.getItem('dark-mode') === 'true';
  });

  // Toggle the theme and update localStorage
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle('light-mode', !newMode);
      localStorage.setItem('dark-mode', newMode ? 'true' : 'false');
      return newMode;
    });
  };

  // Sync the initial mode to the DOM
  useEffect(() => {
    document.documentElement.classList.toggle('light-mode', !isDarkMode);
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Hook for accessing the theme
export const useTheme = () => useContext(ThemeContext);
