import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { fetchUserDocument, updateLastLoggedIn } from './services/dbService';
import CompleteProfile from './Agent/CompleteProfile';
import LoginForm from './components/UI/LoginForm';
import MainLayout from './Layouts/MainLayout';
import SecondaryLayout from './Layouts/SecondaryLayout';
import AgentDashboard from './Agent/AgentDashboard';
import LandlordDashboard from './Landlord/LandlordDashboard';
import TransactionEnd from './TransactionEnd/TransactionEnd';
// import ContractorDashboard from "./Contractor/ContractorDashboard";

const AppRouter = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  // onLoginSuccess handles login and role-based routing
  const onLoginSuccess = async (user) => {
    try {
      const userDoc = await fetchUserDocument(user.email);
      const role = userDoc?.role;

      if (!role) {
        alert('No valid role found. Please contact support.');
        return;
      }

      // Update lastLoggedIn field in Firestore
      await updateLastLoggedIn(user.email);

      setUserRole(role);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error during login flow:', error);
      alert('An error occurred during login. Please try again later.');
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await fetchUserDocument(user.email);
          setUserRole(userDoc?.role || null);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          {/* Complete profile route */}
          <Route path="/complete-profile" element={<CompleteProfile />} />


          {/* Payment complete route */}
          <Route path="/transaction-end" element={<TransactionEnd />} />

          {/* Role-specific routes */}
          {userRole === 'Agent' && (
            <Route path="/agent-dashboard" element={<SecondaryLayout><AgentDashboard /></SecondaryLayout>} />
          )}

          {userRole === 'Landlord' && (
            <Route path="/landlord-dashboard" element={ <SecondaryLayout><LandlordDashboard /></SecondaryLayout> } />
          )}

          {userRole === 'Contractor' && (
            <Route path="/*" element={ <MainLayout isCollapsed={false} setIsCollapsed={() => {}} isDarkMode={false} toggleDarkMode={() => {}} isContractor={true} /> } />
          )}

          {(userRole === 'Owner' || userRole === 'Admin') && (
            <Route path="/*" element={ <MainLayout isCollapsed={false} setIsCollapsed={() => {}} isDarkMode={false} toggleDarkMode={() => {}} /> } />
          )}

          {/* Fallback for authenticated users */}
          <Route path="*" element={<Navigate to={`/${userRole?.toLowerCase()}-dashboard`} />} />
        </>
      ) : (
        <>
          {/* Login route */}
          <Route path="*" element={<LoginForm onLogin={onLoginSuccess} />} />
        </>
      )}
    </Routes>
  );

  
};

export default AppRouter;
