import React, { useState, useEffect } from "react";
import { fetchPropertyById } from "../services/dbService";
import '../styles/PropertyDetail.css';


const PropertyDetail = ({ propertyId }) => {
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadProperty = async () => {
      try {
        const fetchedProperty = await fetchPropertyById(propertyId);
        setProperty(fetchedProperty);
        setLoading(false);
      } catch (err) {
        console.error("PropertyDetailForm: Error loading property:", err);
        setError("Failed to load property details. Please try again later.");
        setLoading(false);
      }
    };

    loadProperty();
  }, [propertyId]);

  if (loading) return <p>Loading property details...</p>;
  if (error) return <p>{error}</p>;

  return (


<div className="property-detail-container">

{/* Property Panel */}
<div className="detail-panel">
  <h2>Property Details</h2>
  <form className="detail-form">
    <div className="detail-row">
      <label htmlFor="property-id">Property ID</label>
      <input id="property-id" value={property?.id || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="property-address">Address</label>
      <input id="property-address" value={property?.address || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="property-postcode">Postcode</label>
      <input id="property-postcode" value={property?.postcode || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="property-agency">Agency</label>
      <input id="property-agency" value={property?.agency || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="property-contact-number">Contact Number</label>
      <input id="property-contact-number" value={property?.contactNumber || "N/A"} readOnly />
    </div>
  </form>
</div>

{/* Landlord Panel */}
<div className="detail-panel">
  <h2>Landlord Details</h2>
  <form className="detail-form">
    <div className="detail-row">
      <label htmlFor="landlord-first-name">First Name</label>
      <input id="landlord-first-name" value={property?.user?.firstName || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="landlord-last-name">Last Name</label>
      <input id="landlord-last-name" value={property?.user?.lastName || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="landlord-profile-complete">Profile Complete</label>
      <input id="landlord-profile-complete" value={property?.user?.isProfileComplete ? "Yes" : "No"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="landlord-email">Email</label>
      <input id="landlord-email" value={property?.landlordsEmail || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="landlord-mobile">Mobile Number</label>
      <input id="landlord-mobile" value={property?.user?.mobileNumber || "N/A"} readOnly />
    </div>
    <div className="detail-row">
      <label htmlFor="landlord-invitation-date">Invitation Date</label>
      <input id="landlord-invitation-date" value={property?.invitationDate ? new Date(property.invitationDate.seconds * 1000).toLocaleDateString("en-GB") : "N/A"} readOnly />
    </div>
  </form>
</div>
</div>



  );
};

export default PropertyDetail;
