// src/Shared/TradeCellRenderer.js
import React from 'react';
import { tradeDecorator } from './tradeDecorator'; // Adjust path if needed

const TradeCellRenderer = ({ value }) => {
  if (!value) {
    return <span style={{ color: '#888' }}>No Trade</span>;
  }
  return tradeDecorator(value); // Pass the trade name to tradeDecorator
};

export default TradeCellRenderer;
