import React, { useState } from "react";
import styles from "../styles/SettingsPlatinum.module.css";
import { calcPlatinumEndTime } from "../utils/jobVisibility"; // Import the functions

const SettingsPlatinum = () => {
  // Example `sut` array
  const sut = [
    { day: 27, hour: 11, minute: 12 },
    // { day: 30, hour: 0, minute: 1 },
    // { day: 29, hour: 23, minute: 59 },
    // { day: 28, hour: 3, minute: 11 },
    // { day: 27, hour: 20, minute: 33 },
    // { day: 27, hour: 17, minute: 25 },
    // { day: 6, hour: 6, minute: 14 },
    // { day: 2, hour: 16, minute: 12 },
    // { day: 2, hour: 11, minute: 22 },
  ];

  // Function to generate date from `sut` array item
  const getDateFromSut = (sutItem) => {
    const now = new Date();
    now.setDate(sutItem.day); // Set the day from sut array
    now.setHours(sutItem.hour, sutItem.minute, 0, 0); // Set the hour and minute
    return now.toISOString().slice(0, 16); // Format as required
  };

  const translateDateString = (dateStringIn) => {
    return new Date(dateStringIn).toLocaleString();
  };

  // Simulated time controlled by UI, defaulting to current date/time
  const [simulatedTime, setSimulatedTime] = useState(new Date().toISOString().slice(0, 16)); // Default simulated time (now)

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Platinum Settings Test Harness</h1>

      {/* Editable SIMULATED TIME Input */}
      <div>
        <label className={styles.label}>Simulated Time:</label>
        <input
          type="datetime-local"
          value={simulatedTime}
          onChange={(e) => setSimulatedTime(e.target.value)}
          className={styles.input}
        />
      </div>

      {/* Loop through the `sut` array and generate the UI for each item */}
      {sut.map((sutItem, index) => {
        const quotePaidAt = getDateFromSut(sutItem); // Get Quote Paid At date from `sut`

        // const PlatinumEndTime = calculatePlatinumEndTime(quotePaidAt, simulatedTime);
        const PlatinumEndTime = calcPlatinumEndTime(quotePaidAt);
        return (
          <div key={index}>
            <h2 className={styles.subtitle}>Case {index}: Paid At: {translateDateString(quotePaidAt)}</h2>
            <p className={styles.text}>
              <span className={styles.makeBold}>Simulated Time:</span> {translateDateString(simulatedTime)}
            </p>
            <p className={styles.text}>
            <span className={styles.makeBold}>Quote Paid:</span> {translateDateString(quotePaidAt)}
            </p>
            <p className={styles.text}>
            <span className={styles.makeBold}>Platinum Until:</span> {PlatinumEndTime ? new Date(PlatinumEndTime).toLocaleString() : "Invalid Time "}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default SettingsPlatinum;
