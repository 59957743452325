import React, { useEffect, useState } from 'react';
import { fetchTrades, addTrade, updateTrade, deleteTrade } from '../services/tradesService';
import FlashMessage from '../components/UI/FlashMessage';

import '../styles/SettingsTrades.css';

const SettingsTrades = () => {
  const [trades, setTrades] = useState([]);
  const [newTrade, setNewTrade] = useState({ tradename: '', enabled: false, textColour: '#000000', backgroundColour: '#FFFFFF' });
  const [flashMessage, setFlashMessage] = useState('');

  useEffect(() => {
    const getTrades = async () => {
      const tradesData = await fetchTrades();
      tradesData.sort((a, b) => a.tradename.localeCompare(b.tradename));
      setTrades(tradesData);
    };
    getTrades();
  }, []);

  const showFlashMessage = (message) => {
    setFlashMessage(message);
    setTimeout(() => {
      setFlashMessage('');
    }, 3000); // Message will disappear after 3 seconds
  };

  const handleAddTrade = async () => {
    if (newTrade.tradename.trim()) {
      const addedTrade = await addTrade(newTrade);
      setTrades([...trades, addedTrade]);
      setNewTrade({ tradename: '', enabled: false, textColour: '#000000', backgroundColour: '#FFFFFF' });
      showFlashMessage('Trade added successfully');
    }
  };

  const handleUpdateTrade = async (id, updatedTrade) => {
    await updateTrade(id, updatedTrade);
    setTrades(trades.map(trade => (trade.id === id ? { ...trade, ...updatedTrade } : trade)));
    showFlashMessage('Trade updated successfully');
  };

  const handleDeleteTrade = async (id) => {
    await deleteTrade(id);
    setTrades(trades.filter(trade => trade.id !== id));
    showFlashMessage('Trade deleted successfully');
  };

  return (
    <div className="settings-trades-container">
      <h2>Manage Trades</h2>
      <div className="trade-form">
        <input type="text" placeholder="Add a Trade" value={newTrade.tradename} onChange={(e) => setNewTrade({ ...newTrade, tradename: e.target.value })} />
        <label>
          Enabled
          <input type="checkbox" checked={newTrade.enabled} onChange={(e) => setNewTrade({ ...newTrade, enabled: e.target.checked })} />
        </label>
        <label>
          Text Colour
          <input type="color" value={newTrade.textColour} onChange={(e) => setNewTrade({ ...newTrade, textColour: e.target.value })} />
        </label>
        <label>
          Background Colour
          <input type="color" value={newTrade.backgroundColour} onChange={(e) => setNewTrade({ ...newTrade, backgroundColour: e.target.value })} />
        </label>
        <button onClick={handleAddTrade}>Add Trade</button>
      </div>
      <ul className="trade-list">
        {trades.map((trade) => (
          <li key={trade.id} className="trade-list-item" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input
              type="text"
              value={trade.tradename}
              style={{ width: '150px' }} // Shorter width for name input
              onChange={(e) => handleUpdateTrade(trade.id, { ...trade, tradename: e.target.value })}
            />
            <div
              style={{
                padding: '5px 15px',
                backgroundColor: trade.backgroundColour || '#FFFFFF',
                color: trade.textColour || '#000000',
                display: 'inline-block',
                textAlign: 'center',
                border: '1px solid #ddd',
                borderRadius: '20px', // Add this for the lozenge shape
                minWidth: '75px',
              }}
            >
              {trade.tradename || 'Example'}
            </div>
            <label>
              Enabled
              <input
                type="checkbox"
                checked={trade.enabled}
                onChange={(e) => handleUpdateTrade(trade.id, { ...trade, enabled: e.target.checked })}
              />
            </label>
            <label>
              Text Colour
              <input
                type="color"
                value={trade.textColour || '#000000'}
                onChange={(e) => handleUpdateTrade(trade.id, { ...trade, textColour: e.target.value })}
              />
            </label>
            <label>
              Background Colour
              <input
                type="color"
                value={trade.backgroundColour || '#FFFFFF'}
                onChange={(e) => handleUpdateTrade(trade.id, { ...trade, backgroundColour: e.target.value })}
              />
            </label>
            <button onClick={() => handleUpdateTrade(trade.id, trade)}>Update</button>
            <button onClick={() => handleDeleteTrade(trade.id)}>Delete</button>
          </li>
        ))}
      </ul>
      {flashMessage && <FlashMessage message={flashMessage} />}
    </div>
  );
    


};

export default SettingsTrades;
