// src/Panels/JobsAvailable.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const JobsAvailable = ({ isSingleColumn, onRowSelectAction, isContractor}) => {
  const [availableJobs, setAvailableJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsAvailableJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    {
      headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.5,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
  ];

  useEffect(() => {
    const loadAvailableJobs = async () => {
      const options = { status: 2 };
      const jobs = await fetchJobs(options);
      setAvailableJobs(jobs);
    };

    loadAvailableJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;

    const tabModes = isContractor
    ? {
        Quote: 'readOnly',
        Detail: 'readOnly',
        Comments: 'hide',
        Appointments: 'hide',
        Property: 'hide',
      }
    : {
        Quote: 'readWrite',
        Detail: 'readWrite',
        Comments: 'readWrite',
        Appointments: 'hide',
        Property: 'readOnly',
      };
  

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <GridPanel
      title="Jobs Available"
      columnDefs={columnDefsAvailableJobs}
      rowData={availableJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick} // Pass the row click handler
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobsAvailable;
