import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import { fetchJobsForAdminToPrice } from '../services/dbService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const JobsToPrice = ({ isSingleColumn, onRowSelectAction }) => {
  const [jobsToPrice, setJobsToPrice] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsJobsToPrice = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },


    {
      headerName: "TRADE",
      field: "tradeName", // Field to filter on
      flex: 1.7,
      filter: true,
      cellRenderer: TradeCellRenderer, // Render the trade name dynamically
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },


    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "DATE CREATED", field: "createdAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadJobsToPrice = async () => {
      try {
        const options = { status: 0 }; // Only fetch jobs with status 0 (Awaiting Pricing)
        const jobs = await fetchJobsForAdminToPrice(options);
        setJobsToPrice(jobs);
      } catch (error) {
        console.error('JobsToPrice: Error fetching jobs to price:', error);
      }
    };

    loadJobsToPrice();
  }, []);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readWrite',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  // Local handlers for panel behavior
  const toggleLayout = () => setIsCollapsed(false);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Jobs To Quote"
      columnDefs={columnDefsJobsToPrice}
      rowData={jobsToPrice}
      height={panelHeight}
      onResize={handleResize}
      isSingleColumn={isSingleColumn}
      toggleLayout={toggleLayout}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick} // Pass the row click handler
    />
  );
};

export default JobsToPrice;

