// src/Dashboard.js

import React, { useState } from 'react';
import '../styles/Dashboard.css';
import JobsAvailable from '../Panels/JobsAvailable';
import JobsAccepted from '../Panels/JobsAccepted';
import StartDateOffered from '../Panels/StartDateOffered';
import DateAcceptedAwaitingStartDate from '../Panels/DateAcceptedAwaitingStartDate';
import JobInProgress from '../Panels/JobInProgress';
import JobCompletedInvoiceSent from '../Panels/JobCompletedInvoiceSent';
import JobCompletedClosed from '../Panels/JobCompletedClosed';
import JobCancelled from '../Panels/JobCancelled';
import JobTabs from '../Job/JobTabs';

const ContractorDashboard = () => {
  const [isSingleColumn] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null); // Track the selected job
  const [tabModes, setTabModes] = useState({});

  const handleRowSelected = (jobData, tabModes) => {
    setSelectedJob(jobData);
    setTabModes(tabModes);
  };

  const handleBackToDashboard = () => {
    setSelectedJob(null); // Clear the selected job and return to the dashboard
  };

  return (
    <div>
      {selectedJob ? (
        // Show JobTabs when a job is selected
        <JobTabs jobData={selectedJob} onReturnToDashboard={handleBackToDashboard} tab={'Appointments'} isAdmin={false} tabModes={tabModes} />
      ) : (
        // Default dashboard content
        <>
        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <JobsAvailable isSingleColumn={false} onRowSelectAction={handleRowSelected} isContractor={true} />
          <JobsAccepted isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} tab="Appointments"/>
        </div>

        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <StartDateOffered isSingleColumn={false} onRowSelectAction={handleRowSelected} isContractor={true} />
          <DateAcceptedAwaitingStartDate isSingleColumn={false} onRowSelectAction={handleRowSelected} isContractor={true} />
        </div>

        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <JobInProgress isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} />
          <JobCompletedInvoiceSent isSingleColumn={false} onRowSelectAction={handleRowSelected} isContractor={true} />
        </div>

        <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
          <JobCompletedClosed isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} />
          <JobCancelled isSingleColumn={false} onRowSelectAction={handleRowSelected } isContractor={true} />
        </div>

        </>
      )}


    </div>
  );
};

export default ContractorDashboard;
