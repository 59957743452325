// src/Shared/titleDecorator.js

export const titleDecorator = (title, backgroundColor = "pink") => {
  if (!title) {
    console.warn("titleDecorator received an empty or undefined title.");
    return null;
  }

  return (
    <div
      style={{
        backgroundColor,
        color: "white", // White text
        padding: "5px 15px", // Adds spacing around the title
        fontSize: "16px", // Font size for better visibility
        fontWeight: "bold", // Makes the title bold
        textAlign: "center", // Centers the text
        borderRadius: "4px", // Subtle rounded corners
        display: "inline-block", // Keeps the element inline
        width: "200px", // Hard-coded width
      }}
    >
      {title}
    </div>
  );
};
