import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase'; // Firebase auth instance
import { getDoc, doc } from 'firebase/firestore'; // For fetching role from Firestore
import { db } from '../../firebase'; // Firestore instance
import { useFeedback } from '../../context/FeedbackContext'; // Importing the custom hook
import '../../styles/Header.css';

const Header = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const { feedback, setFeedback } = useFeedback(); // Access feedback state and function
  const [fadeOut, setFadeOut] = useState(false); // Track the fade-out status

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        console.log('Header: User signed in:', firebaseUser.email);
        setUser(firebaseUser);

        try {
          const role = await fetchUserRole(firebaseUser.email);
          setUserRole(role);
        } catch (error) {
          console.error('Header: Error fetching user role:', error);
        }
      } else {
        console.log('Header: No user signed in');
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserRole = async (email) => {
    try {
      const docRef = doc(db, 'users', email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().role;
      } else {
        return 'Unknown';
      }
    } catch (error) {
      console.error('Header: Error fetching role for email:', error);
      return 'Unknown';
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('Header: User signed out');
      setUser(null);
      setUserRole(null);
    } catch (error) {
      console.error('Header: Error during logout:', error);
    }
  };

  useEffect(() => {
    // If there's a feedback message, start a timer to fade it out after 5 seconds
    if (feedback) {
      setFadeOut(false); // Reset fadeOut before starting fade
      const timer = setTimeout(() => {
        setFadeOut(true);
        // Optionally clear the feedback message after fading out
        setTimeout(() => setFeedback(''), 500); // Clear feedback after fade-out duration
      }, 5000); // Change the duration to your desired time in milliseconds

      return () => clearTimeout(timer); // Cleanup on unmount or if feedback changes
    }
  }, [feedback, setFeedback]);

  return (
    <div className="header-panel">
      <div className="header-left">
        <a href="#0" aria-label="MMG logo">
          <img src="mmg-logo.png" alt="Logo" className="logo" />
        </a>
      </div>

      <div className="header-center">
        <span className="header-content-panel-title">
          <span className="header-title-text">Complete Maintenance Hub</span>
          <span className="header-slogan">Property Maintenance Made Easy</span>
        </span>

        {/* Feedback message */}
        {feedback && (
          <span className={`feedback-message ${fadeOut ? 'fade-out' : ''}`}>{feedback}</span>
        )}
      </div>

      <div className="header-right">
        {user ? (
          <div className="user-info">
            <span className="user-email">{user.email}</span>
            <span className="user-role">{userRole || 'Fetching role...'}</span>
            <button className="logout-button" onClick={handleLogout}>
              Log Out
            </button>
          </div>
        ) : (
          <span className="not-signed-in">Not signed in</span>
        )}
      </div>
    </div>
  );
};

export default Header;
