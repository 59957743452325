import React, { useState } from 'react';
import { sendSignInLinkToEmail } from 'firebase/auth'; // Import Firebase auth methods
import { auth, db } from '../firebase'; // Firebase auth and Firestore instances
import { setDoc, doc } from 'firebase/firestore'; // Import setDoc and doc
import { serverTimestamp } from 'firebase/firestore';
import styles from './SettingsAddUser.module.css';

const SettingsAddUser = () => {
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteError, setInviteError] = useState(null);
  const [inviteSuccess, setInviteSuccess] = useState(null);
  const [role, setRole] = useState(''); // Initialize with an empty string
  const [isPlatinum, setIsPlatinum] = useState(false); // State for Platinum checkbox

  const adminEmail = auth.currentUser?.email;

  const getRoleSpecificData = (role) => {
    const baseData = {
      email: inviteEmail,
      enabled: true,
      firstName: "",
      invitedAt: serverTimestamp(),
      invitedById: adminEmail,
      invitedByName: "Admin",
      isProfileComplete: false,
      lastLoggedIn: null,
      lastName: "",
      role,
    };
  
    switch (role) {
      case "Agent":
        return {
          ...baseData,
          companyAddress: "",
          companyName: "",
          companyPostcode: "",
          officePhoneNumber: "",
          paymentAccountNumber: "",
          paymentBankName: "",
          paymentSortCode: "",
        };
  
      case "Landlord":
        return {
          ...baseData,
          mobileNumber: "",
        };
  
      case "Contractor":
        return {
          ...baseData,
          companyAddress: "",
          companyName: "",
          companyPostcode: "",
          mobileNumber: "",
          officePhoneNumber: "",
          paymentAccountNumber: "",
          paymentBankName: "",
          paymentSortCode: "",
          subscriptionExpires: null, // Defaults to null; can be updated later
          subscriptionType: isPlatinum ? 0 : null, // Platinum: 0, Non-Platinum: null
          tradesId: null,
          tradesName: "",
        };
  
      default:
        return baseData;
    }
  };
  
  // Handle invite submission
  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    setInviteError(null);
    setInviteSuccess(null);

    if (!inviteEmail || !role) {
      setInviteError('Email and role are required');
      return;
    }

    try {
      // Step 1: Add invited user to Firestore with a complete but blank record
      const userData = getRoleSpecificData(role, isPlatinum);
      await setDoc(doc(db, 'users', inviteEmail), userData);

      // Step 2: Send the email invitation using Firebase Authentication
      const actionCodeSettings = {
        url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(inviteEmail)}`,
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, inviteEmail, actionCodeSettings);

      // Step 3: Provide feedback and reset the form
      setInviteSuccess(`Invitation sent to ${inviteEmail} with role ${role}`);
      setInviteEmail('');
      setRole('');
      setIsPlatinum(false); // Reset Platinum checkbox
    } catch (error) {
      console.error('Failed to send invitation:', error);
      setInviteError('Failed to send invitation. ' + error);
    }
  };

  return (
    <div className={styles.addUserBody}>
      <div className={styles.addUserFormContainer}>
        <h2>Add User</h2>
        <form onSubmit={handleInviteSubmit}>
          <label className={styles.addUserLabel}>Role:</label>
          <select
            className={styles.addUserSelect}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="" disabled>Select</option>
            <option value="Owner">Owner</option>
            <option value="Admin">Admin</option>
            <option value="Agent">Agent</option>
            <option value="Contractor">Contractor</option>
          </select>

          {role === 'Contractor' && (
            <div className={styles.addUserCheckboxGroup}>
              <input
                type="checkbox"
                id="platinum-checkbox"
                checked={isPlatinum}
                onChange={(e) => setIsPlatinum(e.target.checked)}
              />
              <label htmlFor="platinum-checkbox" className={styles.addUserLabel}>
                Platinum user
              </label>
            </div>
          )}

          <label className={styles.addUserLabel}>Email address to invite:</label>
          <input
            type="email"
            className={styles.addUserInput}
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            required
          />

          {inviteError && (
            <p className={styles.addUserErrorMessage}>{inviteError}</p>
          )}
          {inviteSuccess && (
            <p className={styles.addUserSuccessMessage}>{inviteSuccess}</p>
          )}

        <div className={styles.addUserButtonGroup}>
          <button type="submit" className={styles.addUserButton}>
            Send Invite
          </button>
        </div>

        </form>
      </div>
    </div>
  );
};

export default SettingsAddUser;
