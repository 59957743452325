import React, { useState, useEffect } from 'react';
import GridPanel from '../components/GridPanel';
import { formatDateOnly } from '../Utilities/dateUtils';
import { fetchJobs } from '../services/jobService';
import { STATUS_MAPPING, fetchPropertyDetailsForAgent } from '../services/dbService';
import { auth } from '../firebase';

const JobsCompleted = ({ handleRowClick }) => {
  const [completedJobs, setCompletedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userEmail = auth.currentUser?.email;

  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.5 },
    { headerName: "ADDRESS", field: "address", flex: 1.1 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "STATUS", field: "status", flex: 0.5, valueFormatter: (params) => { return STATUS_MAPPING[String(params.value)]?.label || STATUS_MAPPING.default.label; }},
    { headerName: "COMPLETED DATE", field: "completedDate", flex: 0.7, valueFormatter: (params) => formatDateOnly(params.value) }
  ];

useEffect(() => {
  const loadCompletedJobs = async () => {
    try {
      setLoading(true);
      const options = { 
        status: 9,
        agentId: userEmail, 
      };
      const jobs = await fetchJobs(options);

      // Enrich each job with its property details
      const enrichedJobs = await Promise.all(
        jobs.map(async (job) => {
          try {
            const propertyDetails = await fetchPropertyDetailsForAgent(job.propertyId, job.agentId);
            return {
              ...job,
              address: `${propertyDetails.address}, ${propertyDetails.postcode}`, // Combine address and postcode
            };
          } catch (error) {
            console.error(`Failed to fetch property details for job ${job.jobId}:`, error);
            return { ...job, address: "Property details unavailable" }; // Fallback
          }
        })
      );

      setCompletedJobs(enrichedJobs); // Update state with enriched jobs
    } catch (error) {
      console.error("Error fetching completed jobs for agent:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  loadCompletedJobs();
}, [userEmail]);

  const tabModes = {
    Detail: 'readOnly',
    Comments: 'readOnly',
    Quote: 'hide',
    Appointments: 'hide',
    Property: 'hide',
  };

  const onRowClick = (jobData) => {
    handleRowClick(jobData, tabModes);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading approved jobs: {error.message}</p>;

  return(
    <GridPanel title="Jobs Completed & Closed" rowData={completedJobs} columnDefs={columnDefs} onRowClick={onRowClick} />
  )
};

export default JobsCompleted;