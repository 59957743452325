import React, { useState } from "react";
import { visibilityMap } from '../utils/roleVisibilityMap';
import '../styles/ProfileForm.css'

const ProfileForm = ({ user, onClose }) => {
  
  const [formData, setFormData] = useState(user);
  const [currentRole, setCurrentRole] = useState(""); 

  const toggleVisibility = (role) => {
    setCurrentRole(role);
  };

  const isFieldHidden = (fieldName) => {
    return currentRole && !visibilityMap[currentRole]?.includes(fieldName);
  };

 const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    // Handle form submission logic here (e.g., API call)
  };

  return (
    <div className="profile-form-container">
      <form onSubmit={handleSubmit}>
        {/* First Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("firstName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">First Name</label>
            <input type="text" className="profile-form-input" value={formData.firstName} name="firstName" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("lastName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Last Name</label>
            <input type="text" className="profile-form-input" value={formData.lastName} name="lastName" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("email") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Email</label>
            <input type="email" className="profile-form-input" value={formData.email} name="email" onChange={handleChange} required />
          </div>
        </div>
  
        {/* Second Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("companyName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Company Name</label>
            <input type="text" className="profile-form-input" value={formData.companyName} name="companyName" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("companyAddress") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Company Address</label>
            <input type="text" className="profile-form-input" value={formData.companyAddress} name="companyAddress" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("companyPostcode") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Company Postcode</label>
            <input type="text" className="profile-form-input" value={formData.companyPostcode} name="companyPostcode" onChange={handleChange} />
          </div>
        </div>
  
        {/* Third Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("companyMainContactName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Company Main Contact Name</label>
            <input type="text" className="profile-form-input" value={formData.companyMainContactName} name="companyMainContactName" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("officePhoneNumber") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Office Phone Number</label>
            <input type="text" className="profile-form-input" value={formData.officePhoneNumber} name="officePhoneNumber" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("mobileNumber") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Mobile Number</label>
            <input type="text" className="profile-form-input" value={formData.mobileNumber} name="mobileNumber" onChange={handleChange} />
          </div>
        </div>
  
        {/* Fourth Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("paymentBankName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Payment Bank Name</label>
            <input type="text" className="profile-form-input" value={formData.paymentBankName} name="paymentBankName" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("paymentAccountNumber") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Payment Account Number</label>
            <input type="text" className="profile-form-input" value={formData.paymentAccountNumber} name="paymentAccountNumber" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("paymentSortCode") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Payment Sort Code</label>
            <input type="text" className="profile-form-input" value={formData.paymentSortCode} name="paymentSortCode" onChange={handleChange} />
          </div>
        </div>
  
        {/* Fifth Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("invitedAt") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Invited At</label>
            <input type="text" className="profile-form-input" value={formData.invitedAt} name="invitedAt" onChange={handleChange} />
          </div>
          <div className={isFieldHidden("enabled") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Enabled</label>
            <input type="checkbox" className="profile-form-input" checked={formData.enabled} name="enabled" onChange={(e) => handleChange({ target: { name: "enabled", value: e.target.checked } })} />
          </div>
          <div className={isFieldHidden("isProfileComplete") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Is Profile Complete</label>
            <input type="checkbox" className="profile-form-input" checked={formData.isProfileComplete} name="isProfileComplete" onChange={(e) => handleChange({ target: { name: "isProfileComplete", value: e.target.checked } })} />
          </div>
        </div>
  
        {/* Sixth Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("lastLoggedIn") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Last Logged In</label>
            <input type="text" className="profile-form-input" value={formData.lastLoggedIn} name="lastLoggedIn" onChange={handleChange} readOnly />
          </div>
          <div className={isFieldHidden("role") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Role</label>
            <input type="text" className="profile-form-input" value={formData.role} name="role" onChange={handleChange} required />
          </div>
          <div className={isFieldHidden("invitedByName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Invited By Name</label>
            <input type="text" className="profile-form-input" value={formData.invitedByName} name="invitedByName" onChange={handleChange} />
          </div>
        </div>
  
        {/* Seventh Row */}
        <div className="profile-form-row">
          <div className={isFieldHidden("invitedById") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Invited By ID</label>
            <input 
              type="text" 
              className="profile-form-input" 
              value={formData.invitedById} 
              name="invitedById" 
              onChange={handleChange} 
            />
          </div>
          <div className={isFieldHidden("tradesId") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Trades ID</label>
            <input 
              type="text" 
              className="profile-form-input" 
              value={formData.tradesId} 
              name="tradesId" 
              onChange={handleChange} 
            />
          </div>
          <div className={isFieldHidden("tradesName") ? "profile-form-hidden" : ""}>
            <label className="profile-form-label">Trades Name</label>
            <input 
              type="text" 
              className="profile-form-input" 
              value={formData.tradesName} 
              name="tradesName" 
              onChange={handleChange} 
            />
          </div>
        </div>
  
        {/* Role Toggle Buttons */}
        <div className="profile-form-row">
          <button type="button" className="profile-form-button" onClick={() => toggleVisibility("agent")}>Agent</button>
          <button type="button" className="profile-form-button" onClick={() => toggleVisibility("landlord")}>Landlord</button>
          <button type="button" className="profile-form-button" onClick={() => toggleVisibility("contractor")}>Contractor</button>
          <button type="button" className="profile-form-button profile-form-cancel" onClick={onClose}>Cancel</button>
        </div>
      </form>
    </div>
  );
    
  
};

export default ProfileForm;

export const ROLE_MAPPING = {
  agent: {
    label: "Agent",
    fields: ["companyName", "companyAddress", "companyPostcode", "companyMainContactName", "officePhoneNumber", "paymentBankName", "paymentAccountNumber", "paymentSortCode", "enabled", "isProfileComplete", "role", "invitedAt", "lastLoggedIn", "invitedByName", "invitedById"],
  },
  landlord: {
    label: "Landlord",
    fields: ["companyName", "companyAddress", "companyPostcode", "mobileNumber", "enabled", "isProfileComplete", "role", "invitedAt", "lastLoggedIn", "invitedByName", "invitedById"],
  },
  contractor: {
    label: "Contractor",
    fields: ["companyName", "companyAddress", "mobileNumber", "officePhoneNumber", "enabled", "isProfileComplete", "role", "invitedAt", "lastLoggedIn", "invitedByName", "invitedById"],
  },
};