export const visibilityMap = {
  agent: [
    "companyAddress",
    "companyName",
    "companyPostcode",
    "email",
    "enabled",
    "firstName",
    "invitedAt",
    "invitedById",
    "invitedByName",
    "isProfileComplete",
    "lastLoggedIn",
    "lastName",
    "officePhoneNumber",
    "paymentAccountNumber",
    "paymentBankName",
    "paymentSortCode",
    "role",
  ],
  landlord: [
    "email",
    "enabled",
    "firstName",
    "invitedAt",
    "invitedById",
    "invitedByName",
    "isProfileComplete",
    "lastLoggedIn",
    "lastName",
    "mobileNumber",
    "role",
  ],
  contractor: [
    "companyAddress",
    "companyName",
    "companyPostcode",
    "email",
    "enabled",
    "firstName",
    "invitedAt",
    "invitedById",
    "invitedByName",
    "isProfileComplete",
    "lastLoggedIn",
    "lastName",
    "mobileNumber",
    "officePhoneNumber",
    "paymentAccountNumber",
    "paymentBankName",
    "paymentSortCode",
    "role",
    "subscriptionExpires",
    "subscriptionType",
    "tradesId",
    "tradesName",
  ],
};
