import React, { useState, useEffect, useCallback, useImperativeHandle } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/LandlordDashboard.css';
import { fetchJobsByPropertyAndStatus, fetchJobsByPropertyAndStatuses } from '../services/dbService';
import AwaitingPriceGrid from '../LandlordDashboard/AwaitingPriceGrid';
import AwaitingApproval from '../LandlordDashboard/AwaitingApproval';
import ApprovedJobs from '../LandlordDashboard/ApprovedJobs';

const LandlordManageProperties = React.forwardRef(({ onRowSelectAction, propertyId }, ref) => {
  const [awaitingPriceJobs, setAwaitingPriceJobs] = useState([]);
  const [awaitingApprovalJobs, setAwaitingApprovalJobs] = useState([]);
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [isSingleColumn] = useState(false);
  const [panelHeight] = useState(300);
  const [collapsedPanels, setCollapsedPanels] = useState({ panel1: false, panel2: false, panel3: false });


  // OLD QUERY Memoize the loadJobs function
  const loadJobs = useCallback(async (status, setter) => {
    try {
      const jobs = await fetchJobsByPropertyAndStatus(propertyId, status);
      setter(jobs);
    } catch (error) {
      console.error(`Error loading jobs with status ${status}:`, error);
    }
  }, [propertyId]);


  const loadJobsIn = useCallback(async (statuses, setter) => {
    if (!Array.isArray(statuses)) {
      console.error('Statuses must be an array');
      return;
    }
  
    try {
      const jobs = await fetchJobsByPropertyAndStatuses(propertyId, statuses); // Adjusted to call the appropriate function
      setter(jobs);
    } catch (error) {
      console.error(`Error loading jobs with statuses ${statuses.join(', ')}:`, error);
    }
  }, [propertyId]);
  

  // Define refreshGrid function
  const refreshGrid = useCallback(() => {
    loadJobs(0, setAwaitingPriceJobs);
    loadJobs(1, setAwaitingApprovalJobs);
    loadJobsIn([2,3], setApprovedJobs);
  }, [loadJobs, loadJobsIn]);

  // Expose refreshGrid to parent using ref
  useImperativeHandle(ref, () => ({
    refreshGrid,
  }));

  // Initial load and dependency-based refresh
  useEffect(() => {
    if (!propertyId) {
      alert('Property ID is required to load jobs.');
      console.error('Property ID is required to load jobs.');
      return;
    }

    refreshGrid(); // Initial load
  }, [propertyId, refreshGrid]);


  const handleJobRowClick = (jobData, tabModes) => {
    onRowSelectAction(jobData.data, tabModes); // Forward jobData and tabModes
  };

  return (
    <div className="manage-properties-container">
      {/* TWO COLUMNS */}
      <div className="row">
        <div className="grid-section">
          <AwaitingPriceGrid
            rowData={awaitingPriceJobs}
            panelHeight={panelHeight}
            isSingleColumn={isSingleColumn}
            collapsedPanels={collapsedPanels}
            setCollapsedPanels={setCollapsedPanels}
            handleJobRowClick={handleJobRowClick}
          />
        </div>

        <div className="grid-section">
          <AwaitingApproval
            rowData={awaitingApprovalJobs}
            panelHeight={panelHeight}
            isSingleColumn={isSingleColumn}
            collapsedPanels={collapsedPanels}
            setCollapsedPanels={setCollapsedPanels}
            handleJobRowClick={handleJobRowClick}
          />
        </div>
      </div>

      <div className="row full-width">
        <div className="grid-section">
          <ApprovedJobs
            rowData={approvedJobs}
            panelHeight={panelHeight}
            isSingleColumn={isSingleColumn}
            collapsedPanels={collapsedPanels}
            setCollapsedPanels={setCollapsedPanels}
            handleJobRowClick={handleJobRowClick}
          />
        </div>
      </div>
    </div>
  );
});

export default LandlordManageProperties;
