import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import TradeCellRenderer from '../Shared/TradeCellRenderer';


const SettingsFilter = () => {
  const rowData = [
    { tradeName: "Plumbing" },
    { tradeName: "Electrics" },
    { tradeName: "Gas" },
    { tradeName: "Plumbing" },
  ];

  const columnDefs = [
    {
      headerName: "TRADE",
      field: "tradeName", // Field to filter on
      flex: 1.7,
      filter: true,
      cellRenderer: TradeCellRenderer, // Render the trade name dynamically
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },

  ];

  return (
    <div>
      <h2>Filter by Trade</h2>
      <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ flex: 1 }}
          debug={true}
        />
      </div>
    </div>
  );
};

export default SettingsFilter;
