// src/Panels/JobCompletedClosed.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const JobCompletedClosed = ({ isSingleColumn, onRowClick }) => {
  const [closedJobs, setClosedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsClosedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.7,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "CLOSED DATE", field: "closedAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadClosedJobs = async () => {
      const options = { status: 9 };
      const jobs = await fetchJobs(options);
      setClosedJobs(jobs);
    };

    loadClosedJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Job Completed & Closed" //9
      columnDefs={columnDefsClosedJobs}
      rowData={closedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobCompletedClosed;
