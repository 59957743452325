import React, { useState, useRef } from 'react';
import DateSelect from '../components/DateSelect';
import ImageUpload from '../components/UI/ImageUpload';
import ThumbnailDisplay from '../components/UI/ThumbnailDisplay';
import { getNextPropertyId } from '../services/propertyService';
import { db, storage } from '../firebase'; // src/firebase.js
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { sendSignInLink } from '../services/userService';
import { useFeedback } from "../context/FeedbackContext";

const InviteLandlordForm = ({ agentData, onSubmit, onClose }) => {
  const [landlordsEmail, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [companyCity, setCompanyCity] = useState('');

  const [postcode, setPostcode]= useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [completionDate, setCompletionDate] = useState({ day: '', month: '', year: '' });
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyImageUrls, setPropertyImageUrls] = useState([]);
  const [floorplanImages, setFloorplanImages] = useState([]);
  const [floorplanImageUrls, setFloorplanImageUrls] = useState([]);
  const { setFeedback } = useFeedback(); // Access the feedback context
  const [isSubmitting, setIsSubmitting] = useState(false);
  const propertyImageUploadRef = useRef(null);
  const floorplanImageUploadRef = useRef(null);

  const handlePhotoUploadComplete = (files) => {
    setPropertyImages(files);
    setPropertyImageUrls(files.map(file => URL.createObjectURL(file)));
  };

  const handleFloorplanUploadComplete = (files) => {
    setFloorplanImages(files);
    setFloorplanImageUrls(files.map(file => URL.createObjectURL(file)));
  };

  const handleDeletePropertyImage = (index) => {
    setPropertyImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setPropertyImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleDeleteFloorplanImage = (index) => {
    setFloorplanImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFloorplanImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedback("Submitting invitation...");
  
    const { day, month, year } = completionDate;
    const formattedCompletionDate = day && month && year ? new Date(year, month - 1, day) : null;
    const agentId = agentData.email;
    const agency = agentData.companyName;

    try {
      // Step 1: Add the landlord to the 'users' collection
      const userData = {
        email: landlordsEmail, // Email of the landlord being invited
        enabled: true,
        firstName: "", // Defaults to empty strings for unfilled fields
        lastName: "",
        mobileNumber: "",
        invitedAt: new Date(),
        invitedById: agentData.email, // The inviting agent's email
        invitedByName: agentData.companyName, // The inviting agent's company
        isProfileComplete: false,
        lastLoggedIn: null,
        role: "Landlord",
      };
  
      const userDocRef = doc(db, 'users', landlordsEmail);
      await setDoc(userDocRef, userData);

      // Step 2: Create a property record
      const propertyId = await getNextPropertyId();
      const propertyData = {
        propertyId,
        agentId,
        agency,
        landlordsEmail,
        address,
        companyCity,
        postcode,
        contactNumber,
        completionDate: formattedCompletionDate || null,
        propertyImages: [],
        propertyFloorplans: [],
        invitationComplete: false,
        invitationDate: new Date(),
      };
  
      const propertyDocRef = doc(db, "properties", propertyId);
      await setDoc(propertyDocRef, propertyData);
  
      const photosFolderPath = `properties/${propertyId}/photos`;
      const floorplansFolderPath = `properties/${propertyId}/floorplans`;
  
      // Step 3: Upload property images (if any)
      const uploadedImageUrls = await Promise.all(
        propertyImages.map(async (file) => {
          const storageRef = ref(storage, `${photosFolderPath}/${file.name}`);
          const uploadTask = await uploadBytesResumable(storageRef, file);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          return downloadURL;
        })
      );
  
      const uploadedFloorplanUrls = await Promise.all(
        floorplanImages.map(async (file) => {
          const storageRef = ref(storage, `${floorplansFolderPath}/${file.name}`);
          const uploadTask = await uploadBytesResumable(storageRef, file);
          const downloadURL = await getDownloadURL(uploadTask.ref);
          return downloadURL;
        })
      );
  
      // Update the property document with image URLs
      await setDoc(propertyDocRef, {
        ...propertyData,
        propertyImages: uploadedImageUrls,
        propertyFloorplans: uploadedFloorplanUrls,
      });
  
      // Step 4: Send the invitation email
      await sendSignInLink(landlordsEmail);

      setFeedback(`Invitation complete: ${landlordsEmail} has been invited`);
    } catch (error) {
      console.error("Error sending invitation:", error);
      setFeedback(`Failed to send the invitation: ${error.message}`);
      } finally {
      setIsSubmitting(false);
      setTimeout(() => handleReset(), 3000);
    }
  };
  
  const handleReset = () => {
    setEmail('');
    setAddress('');
    setPostcode('');
    setCompanyCity('');
    setContactNumber('');
    setCompletionDate({ day: '', month: '', year: '' });
    setPropertyImages([]);
    setPropertyImageUrls([]);
    setFloorplanImages([]);
    setFloorplanImageUrls([]);
    if (propertyImageUploadRef.current) propertyImageUploadRef.current.clearFiles();
    if (floorplanImageUploadRef.current) floorplanImageUploadRef.current.clearFiles();
  };

  return (
    <form onSubmit={handleSubmit} className="invite-form">
      <div className="input-group">
        <label htmlFor="email">New Owner's Email</label>
        <input type="email" id="email" value={landlordsEmail} onChange={(e) => setEmail(e.target.value)} required />
      </div>

      <div className="input-group-row">
        <div className="input-group">
          <label htmlFor="address">Property Address</label>
          <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </div>

        <div className="input-group postcode-group">
          <label htmlFor="postcode">Town / City</label>
          <input type="text" id="companyCity" value={companyCity} onChange={(e) => setCompanyCity(e.target.value)} required />
        </div>

        <div className="input-group postcode-group">
          <label htmlFor="postcode">Postcode</label>
          <input type="text" id="postcode" value={postcode} onChange={(e) => setPostcode(e.target.value)} required />
        </div>
      </div>

      <div className="input-group">
        <label htmlFor="contact-number">Owner Contact Number</label>
        <input type="tel" id="contact-number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
      </div>

      <div className="input-group">
        <label htmlFor="completion-date">Completion Date (Optional)</label>
        <DateSelect dateValue={completionDate} setDateValue={setCompletionDate} hasDefaultDate={false} />
      </div>

      {/* Property Photos Upload with Thumbnails and Delete */}
      <div className="input-group">
        <label>Property Photos</label>
        <ImageUpload ref={propertyImageUploadRef} onFilesSelected={handlePhotoUploadComplete} />
        <ThumbnailDisplay images={propertyImageUrls} onDelete={handleDeletePropertyImage} />
      </div>

      {/* Floorplan Upload with Thumbnails and Delete */}
      <div className="input-group">
        <label>Floorplan</label>
        <ImageUpload ref={floorplanImageUploadRef} onFilesSelected={handleFloorplanUploadComplete} />
        <ThumbnailDisplay images={floorplanImageUrls} onDelete={handleDeleteFloorplanImage} />
      </div>

      {/* Submission feedback message at the bottom */}
      {/* {submissionMessage && (
        <div className={`submission-feedback ${isSubmitting ? 'submitting' : 'completed'}`}>
          {submissionMessage}
        </div>
      )} */}

      <div className="invite-form-actions">
        <button type="button" className="close-button" onClick={onClose}>
          Close Form
        </button>
        <button type="submit" className="invite-button" disabled={isSubmitting}>
          {isSubmitting ? "Sending..." : "Send Invitation"}
        </button>
      </div>
    </form>
  );
};

export default InviteLandlordForm;
