// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAQgv9mYoBxU0UMLa9Ibqt0noNZeZQ1zko",
  authDomain: "mastermg-cb291.firebaseapp.com",
  projectId: "mastermg-cb291",
  storageBucket: "mastermg-cb291.appspot.com",
  messagingSenderId: "855366189878",
  appId: "1:855366189878:web:5b9df2f929245b2eeea997",
  measurementId: "G-8PE5QGXHHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Initialize Firebase Storage
export const storage = getStorage(app); // Export the storage instance

export const functions = getFunctions(app, "europe-west2"); // Match the function's region
