import React, { useState, useEffect } from "react";
import { updateDoc, doc, getDocs, query, collection, where, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../firebase"; // Firebase instances
import { updatePassword, sendSignInLinkToEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import styles from "./CPLandlord.module.css";

const CPLandlord = () => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    agreeToTerms: false,
    firstName: "",
    lastName: "",
    mobileNumber: "",
  });


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = urlParams.get('email');
    if (userEmail) {
      setFormData((prev) => ({ ...prev, email: userEmail }));
    } else {
      console.error('No email found in URL parameters.');
      // Handle the error as needed (e.g., redirect to login)
      setError("Email not found in URL parameters.");
      navigate("/login");
    }
  }, [navigate]);

  // Check if the form is valid
  const isFormValid = formData.agreeToTerms && formData.firstName && formData.lastName && formData.mobileNumber;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return; // Gatekeep the submission
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      if (formData.password) {
        try {
          await updatePassword(auth.currentUser, formData.password);
        } catch (error) {
          if (error.code === "auth/requires-recent-login") {
            alert("Your session has expired. A new login link will be sent to your email.");
            const actionCodeSettings = {
              url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(formData.email)}`,
              handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(auth, formData.email, actionCodeSettings);
            alert("A new login link has been sent to your email.");
            return;
          } else {
            setError("Failed to update password. Please try again.");
            return;
          }
        }
      }

      const userDocRef = doc(db, "users", formData.email);
      await updateDoc(userDocRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobileNumber: formData.mobileNumber,
        isProfileComplete: true,
        lastLoggedIn: serverTimestamp(),
      });

      const propertyQuery = query(collection(db, "properties"), where("landlordsEmail", "==", formData.email));
      const propertySnapshot = await getDocs(propertyQuery);

      if (!propertySnapshot.empty) {
        propertySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { invitationComplete: true });
        });
      }

      setSuccess("Profile updated successfully!");
      navigate("/landlord-dashboard");

    } catch (error) {
      console.error("Error during profile update:", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.brandingHeaderPanel}>
        <img src="/mmg-logo.png" alt="Logo" className={styles.logo} />
        <span>Complete Maintenance Hub</span>
      </div>

      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Personal Information</legend>

          <div className={styles.row}>
            <div>
              <label className={styles.label}>First Name</label>
              <input type="text" className={styles.input} name="firstName" value={formData.firstName || ""} onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Last Name</label>
              <input type="text" className={styles.input} name="lastName" value={formData.lastName || ""} onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Mobile Number</label>
              <input type="text" className={styles.input} name="mobileNumber" value={formData.mobileNumber || ""} onChange={handleChange} required />
            </div>
          </div>

          <div className={styles.row}>
            <div>
              <label className={styles.label}>Email Address</label>
              <input type="email" className={styles.input} value={formData.email || ""} name="email" readOnly />
            </div>
            <div>
              <label className={styles.label}>Password</label>
              <input type="password" className={styles.input} value={formData.password || ""} name="password" onChange={handleChange} required />
            </div>
          </div>

        </fieldset>
        <div className={styles.termsContainer}>
          <input type="checkbox" id="agreeToTerms" name="agreeToTerms" checked={formData.agreeToTerms || false} onChange={handleChange} required />
          <label htmlFor="agreeToTerms" className={styles.label}>I agree to the <button type="button" onClick={() =>  window.open('/terms-and-conditions.html', '_blank', 'width=800,height=600')}>Terms and Conditions</button></label>
        </div>
        <div className={styles.submitContainer}>
          <button type="submit" className={styles.submitButton} disabled={!formData.agreeToTerms || isSubmitting} style={{ opacity: !formData.agreeToTerms ? 0.5 : 1, cursor: !formData.agreeToTerms ? 'not-allowed' : 'pointer' }}>
            {isSubmitting ? <span className={styles.spinner}></span> : "Complete Registration"}
          </button>
        </div>
        <div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{success}</p>}
        </div>
      </form>
    </div>
  );
};

export default CPLandlord;
