// src/Panels/JobCompletedInvoiceSent.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';

const JobCompletedInvoiceSent = ({ isSingleColumn, onRowClick }) => {
  const [invoicedJobs, setInvoicedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsInvoiceSentJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE", field: "tradeName", flex: 1.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "INVOICE SENT DATE", field: "invoiceSentAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadInvoicedJobs = async () => {
      const options = { status: 7 };
      const jobs = await fetchJobs(options);
      setInvoicedJobs(jobs);
    };

    loadInvoicedJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Job Completed - Invoice Sent" //7
      columnDefs={columnDefsInvoiceSentJobs}
      rowData={invoicedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobCompletedInvoiceSent;
