import React, {useEffect, useState} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import {globalConfig} from "../config";

const GenerateEmail = () => {
  const [link, setLink] = useState("");
  const inviteEmail = `invite${Math.floor(100 + Math.random() * 900)}@handstream.co.uk`;

  useEffect(() => {
    const generateLink = async () => {
      try {
        const response = await httpsCallable(getFunctions(), "generateSignInWithEmailLink")({
          userEmail: inviteEmail,
          actionCodeSettings: {
            url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(inviteEmail)}`,
            handleCodeInApp: true,
          },
        });
        setLink(response.data.link);
      } catch (error) {
        console.error("Error generating link:", error.message);
      }
    };
    generateLink();
  }, [inviteEmail]);

  return (
    <div style={{fontFamily: "Arial, sans-serif", lineHeight: "1.6", padding: "20px"}}>
      <div style={{maxWidth: "600px", margin: "0 auto", border: "1px solid #ddd"}}>
        <h2 style={{color: "#333"}}>Hello there,</h2>
        <p>You have been invited to join <strong>{globalConfig.sitename}</strong>, the property maintenance management website.</p>
        <p>Please complete your invitation and set a password for your account.</p>
        {link && (
          <a href={link} style={{display: "inline-block", marginTop: "20px", padding: "10px 15px", backgroundColor: "#007bff", color: "#fff", textDecoration: "none", borderRadius: "5px"}}>
            Complete Profile
          </a>
        )}
        <p style={{marginTop: "20px", color: "#555"}}>If you have any questions, don’t hesitate to contact us via the platform.</p>
        <p style={{marginTop: "20px", fontSize: "0.9em", color: "#888"}}>Best regards,<br/>The {globalConfig.sitename} Team</p>
      </div>
    </div>
  );
};

export default GenerateEmail;
