import React, { useState } from 'react';
import { SystemCommenter } from '../Shared/SystemCommenter';
import { notifyLandlord } from '../services/quoteService';
import { generateAppointmentEmailTextTemplate, generateAppointmentEmailHTMLTemplate } from '../EmailTemplates/emailTemplates'; // Update path if needed
import { sendNotificationEmail } from "../Shared/EmailClient";
import { auth } from '../firebase';
import { globalConfig } from '../config';
import '../styles/AppointmentForm.css';
import { useNavigate } from 'react-router-dom';

const AppointmentForm = ({ jobId, landlordData }) => {
    const navigate = useNavigate();
  
  const [appointmentDetails, setAppointmentDetails] = useState({
    date: '',
    time: '',
    notes: ''
  });

  const isSubmitDisabled = !appointmentDetails.date || !appointmentDetails.time || !appointmentDetails.notes;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppointmentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Appointment details:', appointmentDetails);

    try {

    // add a comment
    await SystemCommenter(
      jobId, 
      'Contractor', 
      'An appointment date has been provided and sent to the landlord for the job.'
    );

    // Notification system 
    const email = auth.currentUser?.email;
    notifyLandlord(email, jobId);

    // Generate Email
    const plainTextBody = generateAppointmentEmailTextTemplate(jobId, landlordData.landlordFirstname);
    const htmlBody = generateAppointmentEmailHTMLTemplate(jobId, landlordData.landlordFirstname);

    await sendNotificationEmail({
      to: landlordData.landlordEmail,
      subject: `${globalConfig.sitename} Appointment - Job ${jobId}`,
      text: plainTextBody, // Plain-text email version
      html: htmlBody,      // HTML email version
    });

    navigate("/contractor-dashboard");

  } catch (error) {
    console.error('Error during submission:', error);
    alert('Failed to complete the operation: ' + error);

  }





  };

  return (
    <form className="appointment-form" onSubmit={handleSubmit}>
      <label>
        Appointment Date:
        <input type="date" name="date" value={appointmentDetails.date} onChange={handleInputChange} />
      </label>
      <label>
        Appointment Time:
        <input type="time" name="time" value={appointmentDetails.time} onChange={handleInputChange} />
      </label>
      <label>
        Notes:
        <textarea name="notes" value={appointmentDetails.notes} onChange={handleInputChange} placeholder="Add any notes..." />
      </label>
      <button className="negative-button" type="submit" style={{ marginLeft: 'auto' }} disabled={isSubmitDisabled}>
        Submit Appointment date and time to Landlord
      </button>
    </form>
  );

};

export default AppointmentForm;
