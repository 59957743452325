import React from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import { STATUS_MAPPING } from '../services/dbService';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const ApprovedJobs = ({ 
  rowData, 
  panelHeight, 
  isSingleColumn, 
  setPanelHeight, 
  setIsSingleColumn, 
  collapsedPanels, 
  setCollapsedPanels, 
  handleJobRowClick 
}) => {
  const columnDefs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.4 },
    { 
      headerName: "TRADE", 
      field: "tradeName",
      flex: 0.7,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)' 
          ? params.data.instructionNotes || 'N/A' 
          : params.data.tradeName;
      }
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "QUOTE DATE", field: "createdAt", flex: 0.7,
      valueFormatter: (params) => formatTimestamp(params.value) },
    { 
        headerName: "STATUS", 
        field: "status", 
        flex: 0.5,
        valueFormatter: (params) => {
          return STATUS_MAPPING[String(params.value)]?.label || STATUS_MAPPING.default.label;
        }
    }   
  ];

  const tabModes = {
    Quote: 'readOnly',
    Detail: 'readOnly',
    Comments: 'readWrite',
    Appointments: 'hide',
    Property: 'hide',
  };
  const handleJobRowClickWrapper = (jobData) => {
    handleJobRowClick(jobData, tabModes);
  };

  return (
    <GridPanel
      title="(Jobs Queued, In Progress, Completed) Approved Jobs"
      rowData={rowData}
      columnDefs={columnDefs}
      height={panelHeight}
      onResize={(change) => setPanelHeight(panelHeight + change)}
      isSingleColumn={isSingleColumn}
      toggleLayout={() => setIsSingleColumn(!isSingleColumn)}
      isCollapsed={collapsedPanels.panel1}
      toggleCollapse={() => setCollapsedPanels(prev => ({ ...prev, panel1: !prev.panel1 }))}
      onRowClick={handleJobRowClickWrapper}
    />
  );
};

export default ApprovedJobs;
