import React from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const AwaitingApproval = ({ 
  rowData, 
  panelHeight, 
  isSingleColumn, 
  setPanelHeight, 
  setIsSingleColumn, 
  collapsedPanels, 
  setCollapsedPanels, 
  handleJobRowClick 
}) => {
  const columnDefs = [
      { headerName: "JOB ID", field: "jobId", flex: 0.9 },
      { 
        headerName: "TRADE", 
        field: "tradeName",
        flex: 1.6,
        cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
        valueGetter: (params) => {
          return params.data.tradeName === 'Other (please specify)' 
            ? params.data.instructionNotes || 'N/A' 
            : params.data.tradeName;
        }
      },
      { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
      { headerName: "QUOTE DATE", field: "createdAt", flex: 1.4,
        valueFormatter: (params) => formatTimestamp(params.value) }

  ];

  const tabModes = {
    Quote: 'readOnly',
    Detail: 'readOnly',
    Comments: 'readWrite',
    Appointments: 'hide',
    Property: 'hide',
  };
  const handleJobRowClickWrapper = (jobData) => {
    handleJobRowClick(jobData, tabModes);
  };
  
  return (
    <GridPanel
      title="(Quote Provided Awaiting Acceptance and Payment) Awaiting Approval"
      rowData={rowData}
      columnDefs={columnDefs}
      height={panelHeight}
      onResize={(change) => setPanelHeight(panelHeight + change)}
      isSingleColumn={isSingleColumn}
      toggleLayout={() => setIsSingleColumn(!isSingleColumn)}
      isCollapsed={collapsedPanels.panel1}
      toggleCollapse={() => setCollapsedPanels(prev => ({ ...prev, panel1: !prev.panel1 }))}
      onRowClick={handleJobRowClickWrapper}
    />
  );
};

export default AwaitingApproval;
