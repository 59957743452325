import React from "react";

const TransactionEnd = () => {
  const handleClosePage = () => {
    // Check if we're in an iframe and the parent window supports postMessage
    if (window.parent && window.parent !== window) {
      // Send a message to the parent to handle iframe closure
      window.parent.postMessage({ action: "closeIframe" }, "*");
    } else {
      // Fallback for standalone page (e.g., non-iframe usage)
      alert("This page cannot be closed directly.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2>Transaction Complete</h2>
      <p>Your transaction has been successfully completed.</p>
      <button
        onClick={handleClosePage}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#007BFF",
          color: "#FFFFFF",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Close This Page
      </button>
    </div>
  );
};

export default TransactionEnd;
