import React, { useEffect, useState } from 'react';
// import { auth } from '../firebase';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { useAuthContext } from '../context/AuthContext';

const JobsAccepted = ({ isSingleColumn, onRowSelectAction, isContractor, tab}) => {
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useAuthContext(); 

  const columnDefsAcceptedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    {
      headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.5,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "ACCEPTED DATE", field: "quoteAcceptedAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadAcceptedJobs = async () => {
      const options = { status: 3 };

      // Check role using RBAC
      const userRole = user?.role;

      // Add additional filter for contractors
      if (userRole === "Contractor") {
        options.contractorName = user.displayName;
      }
      const jobs = await fetchJobs(options);
      setAcceptedJobs(jobs);
    };

    loadAcceptedJobs();
  }, [user.displayName, user?.role]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const roleTabModes = {
      Contractor: {
        Quote: 'readOnly',
        Detail: 'readOnly',
        Comments: 'readWrite',
        Appointments: 'readWrite',
        Property: 'hide',
      },
      Default: {
        Appointments: 'hide',
        Quote: 'readOnly',
        Detail: 'readWrite',
        Comments: 'readWrite',
        Property: 'hide',
        },
    };
    const tabModes = isContractor ? roleTabModes.Contractor : roleTabModes.Default;
    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <GridPanel
      title="Jobs Accepted" // 3
      columnDefs={columnDefsAcceptedJobs}
      rowData={acceptedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick} // Pass the row click handler
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobsAccepted;
