import React, { useState, useEffect } from 'react';
import GridPanel from '../components/GridPanel';
import { formatDateOnly } from '../Utilities/dateUtils';
import { fetchPropertiesSubmittedByAgent } from '../services/dbService';
import { auth } from '../firebase';

const AgentPropertiesSubmitted = ({ handleRowClick }) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const columnDefs = [
    { headerName: "PROPERTY ID", field: "id", flex: 0.6 },
    { headerName: "ADDRESS", field: "address", flex: 1.5 },
    { headerName: "POSTCODE", field: "postcode", flex: 0.5 },
    { headerName: "INVITED", field: "invitationDate", flex: 0.5,
      valueFormatter: (params) => formatDateOnly(params.value) },
    { headerName: "ACCEPTED", field: "inviteAcceptedAt", flex: 0.5,
      valueFormatter: (params) => formatDateOnly(params.value) }
  ];

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setLoading(true);
        const email = auth.currentUser?.email;
        const data = await fetchPropertiesSubmittedByAgent(email);
        setProperties(data);
      } catch (error) {
        console.error('Error fetching properties:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProperties(); // Trigger the fetch
  }, []);

  const tabModes = {
    Property: 'readOnly',
    Quote: 'hide',
    Detail: 'hide',
    Comments: 'hide',
    Appointments: 'hide',
  };

  const onRowClick = (jobData) => {
    handleRowClick(jobData, tabModes);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading properties: {error.message}</p>;

  return (
      <GridPanel title="Your Invited Properties" rowData={properties} columnDefs={columnDefs} onRowClick={onRowClick} />
  );
};

export default AgentPropertiesSubmitted;
