import React, { useState, useEffect } from "react";
import { serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Firebase instances
import { updatePassword, sendSignInLinkToEmail } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore'; 
import { useNavigate } from 'react-router-dom';
import styles from "./CPAgent.module.css";

const CPAgent = () => {

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = urlParams.get('email');
    if (userEmail) {
      setFormData((prev) => ({ ...prev, email: userEmail }));
    } else {
      console.error('No email found in URL parameters.');
      // Handle the error as needed (e.g., redirect to login)
      setError("Email not found in URL parameters.");
      navigate("/login");
    }
  }, [navigate]);

  const [formData, setFormData] = useState({
    agreeToTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Special handling for sort code formatting
    let formattedValue = value;
    if (name === "paymentSortCode") {
      // Remove invalid characters (allow only digits and hyphens)
      const sanitizedValue = value.replace(/[^0-9-]/g, '');
  
      // Extract digits only for validation
      const digitsOnly = sanitizedValue.replace(/-/g, '').slice(0, 6);
  
      // Dynamically add hyphens based on input length
      formattedValue = digitsOnly
        .split('')
        .reduce((acc, char, idx) => {
          if (idx === 2 || idx === 4) {
            return acc + '-' + char; // Insert hyphens after 2nd and 4th digits
          }
          return acc + char;
        }, '');
  
      // Ensure the field is limited to 8 characters (XX-XX-XX)
      formattedValue = formattedValue.slice(0, 8);
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : formattedValue,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);
  
    try {
      // Update password in Firebase Auth
      if (formData.password) {
        try {
          await updatePassword(auth.currentUser, formData.password);
        } catch (error) {
          if (error.code === 'auth/requires-recent-login') {
            alert('Your session has expired. A new login link will be sent to your email.');
  
            // Resend login link for re-authentication
            const actionCodeSettings = {
              url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(formData.email)}`,
              handleCodeInApp: true,
            };
            try {
              await sendSignInLinkToEmail(auth, formData.email, actionCodeSettings);
              alert('A new login link has been sent to your email.');
            } catch (resendError) {
              console.error('Error resending login link:', resendError);
              setError('Failed to resend login link. Please contact support.');
            }
            return; // Stop further processing
          } else {
            setError('Failed to update password. Please try again.');
            return;
          } 
        } finally {
          setIsSubmitting(false); // Stop spinner
        }
      }
       
      // Update Firestore with profile info
      const userDocRef = doc(db, "users", formData.email);
      await updateDoc(userDocRef, {
        companyCity: formData.companyCity,
        companyAddress: formData.companyAddress,
        companyName: formData.companyName,
        companyPostcode: formData.companyPostcode,
        firstName: formData.firstName,
        lastName: formData.lastName,
        officePhoneNumber: formData.officePhoneNumber,
        paymentAccountNumber: formData.paymentAccountNumber,
        paymentBankName: formData.paymentBankName,
        paymentSortCode: formData.paymentSortCode,
        isProfileComplete: true,
        lastLoggedIn: serverTimestamp(),
      });
  
      setSuccess("Profile updated successfully!");
      navigate("/agent-dashboard");
    } catch (error) {
      console.error("Error during profile update:", error);
      setError("Failed to update profile. Please try again.");
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.brandingHeaderPanel}>
        <img src="/mmg-logo.png" alt="Logo" className={styles.logo} />
        <span>Complete Maintenance Hub</span>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Company Information */}
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Company Information</legend>
          <div className={`${styles.row} ${styles.threeColumns}`}>
            <div>
              <label className={styles.label}>Company Name</label>
              <input type="text" className={styles.input} value={formData.companyName || ""} name="companyName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Company Address</label>
              <input type="text" className={styles.input} value={formData.companyAddress || ""} name="companyAddress" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Town / City</label>
              <input type="text" className={styles.input} value={formData.companyCity || ""} name="companyCity" onChange={handleChange} required />
            </div>
          </div>

          <div className={`${styles.row} ${styles.threeColumns}`}>

          <div>
              <label className={styles.label}>Postcode</label>
              <input type="text" className={styles.input} value={formData.companyPostcode || ""} name="companyPostcode" onChange={handleChange} required />
            </div>


          <div>
              <label className={styles.label}>Office Phone Number</label>
              <input type="text" className={styles.input} value={formData.officePhoneNumber || ""} name="officePhoneNumber" onChange={handleChange} required />
            </div>
          </div>


          <div className={`${styles.row} ${styles.threeColumns}`}>
            <div>
              <label className={styles.label}>Bank Name</label>
              <input type="text" className={styles.input} value={formData.paymentBankName || ""} name="paymentBankName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Account Number</label>
              <input type="text" className={styles.input} value={formData.paymentAccountNumber || ""} name="paymentAccountNumber" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Sort Code</label>
              <input type="text" className={styles.input} value={formData.paymentSortCode || ""} name="paymentSortCode" onChange={handleChange} required />
            </div>
          </div>
        </fieldset>
  
        {/* Personal Information */}
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Personal Information</legend>
          <div className={`${styles.row} ${styles.fourColumns}`}>
            <div>
              <label className={styles.label}>Company Contact First Name</label>
              <input type="text" className={styles.input} value={formData.firstName || ""} name="firstName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Company Contact Last Name</label>
              <input type="text" className={styles.input} value={formData.lastName || ""} name="lastName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Email Address</label>
              <input type="email" className={styles.input} value={formData.email || ""} name="email" readOnly />
            </div>
            <div>
              <label className={styles.label}>Password</label>
              <input type="password" className={styles.input} value={formData.password || ""} name="password" onChange={handleChange} required />
            </div>
          </div>
        </fieldset>
  
        {/* Terms and Conditions */}
        <div className={styles.termsContainer}>
          <input type="checkbox" id="agreeToTerms" name="agreeToTerms" checked={formData.agreeToTerms || false} onChange={handleChange} required />
          <label htmlFor="agreeToTerms" className={styles.label}>I agree to the <button type="button" onClick={() =>  window.open('/terms-and-conditions.html', '_blank', 'width=800,height=600')}>Terms and Conditions</button></label>
        </div>
  
        {/* Submit Button */}
        <div className={styles.submitContainer}>
          <button type="submit" className={styles.submitButton} disabled={!formData.agreeToTerms || isSubmitting} style={{ opacity: !formData.agreeToTerms ? 0.5 : 1, cursor: !formData.agreeToTerms ? 'not-allowed' : 'pointer', }}> {isSubmitting ? <span className={styles.spinner}></span> : "Complete Registration"}</button>
        </div>

        {/* Feedback Messages */}
        <div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}
        </div>

      </form>
    </div>
  );
};

export default CPAgent;
