import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { getAuth } from "firebase/auth";
// import GoCardlessPayment from '../components/GoCardlessPayment'; // Adjust path if necessary

const SettingsPayments = () => {
  const [output, setOutput] = useState("Loading...");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        setOutput("You are not logged in.");
        return;
      }

      try {
        const token = await user.getIdTokenResult();
        if (token.claims.role === "admin") {
          setIsAdmin(true);
          setOutput("You are an admin. Preparing to call the Cloud Function...");
        } else {
          setIsAdmin(false);
          setOutput("You do not have admin privileges.");
        }
      } catch (error) {
        setOutput(`Error checking admin status: ${error.message}`);
      }
    };

    checkAdminStatus();
  }, []);

  useEffect(() => {
    if (!isAdmin) return;

    const testFunctionCall = async () => {
      try {
        setOutput("Calling the Cloud Function...");
        const listGoCardlessCustomers = httpsCallable(
          functions,
          "listGoCardlessCustomers",
        );
        const response = await listGoCardlessCustomers();
        setOutput(
          `Function response:\n${JSON.stringify(response.data, null, 2)}`,
        );
      } catch (error) {
        setOutput(
          `Error calling function:\nCode: ${error.code}\nMessage: ${error.message}`,
        );
      }
    };

    testFunctionCall();
  }, [isAdmin]);

  return (
    <div>

      <div>
        <h1>God Cardless Data</h1>
        <pre>{output}</pre>
      </div>

    </div>
  );
};

export default SettingsPayments;
