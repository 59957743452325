import React from "react";
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import ContractorDashboard from "../Contractor/ContractorDashboard";
import Settings from '../Settings/Settings';
import Job from '../Job/Job';
import Notifications from "../Notifications/Notifications";
import '../styles/AdministrationContent.css';

function AdministrationContent({ isCollapsed, isContractor }) {
  

  const mockNotifications = [
    { message: "Job #123 has been approved.", time: "5 minutes ago" },
    { message: "New comment on Job #456.", time: "10 minutes ago" },
    { message: "Reminder: Complete profile setup.", time: "1 hour ago" },
  ];

  return (
    <div className={`page-content ${isCollapsed ? 'collapsed' : ''}`}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contractor-dashboard" element={<ContractorDashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/job" element={<Job />} />
        <Route path="/notifications" element={<Notifications notifications={mockNotifications} />} />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
}

export default AdministrationContent;