import React, { createContext, useState, useContext } from 'react';

// Create the context
const FeedbackContext = createContext();

// Provider component
export const FeedbackProvider = ({ children }) => {
  const [feedback, setFeedback] = useState('');

  return (
    <FeedbackContext.Provider value={{ feedback, setFeedback }}>
      {children}
    </FeedbackContext.Provider>
  );
};

// Custom hook to use the feedback context
export const useFeedback = () => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }
  return context;
};
