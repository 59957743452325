// src/Panels/JobCancelled.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';

const JobCancelled = ({ onRowClick }) => {
  const [cancelledJobs, setCancelledJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsCancelledJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE", field: "tradeName", flex: 1.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "CANCELLED DATE", field: "cancelledAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadCancelledJobs = async () => {
      const options = { status: -1 };
      const jobs = await fetchJobs(options);
      setCancelledJobs(jobs);
    };

    loadCancelledJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Job Cancelled" // -1
      columnDefs={columnDefsCancelledJobs}
      rowData={cancelledJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClick}
      isSingleColumn={true} // Full row panel
    />
  );
};

export default JobCancelled;
