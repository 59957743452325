import React, { useState } from 'react';
import Header from '../components/UI/Header';
import SideNav from '../components/UI/SideNav';
import AdministrationContent from './AdministrationContent';

const MainLayout = ({ isCollapsed, setIsCollapsed, isDarkMode, toggleDarkMode, isContractor }) => {
  const [dashboardKey, setDashboardKey] = useState(0); // State for re-rendering

  const handleDashboardClick = () => {
    // alert('handleDashboardClick'); 
    setDashboardKey((prevKey) => prevKey + 1); // Increment key to force re-render
  };

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Header />
      <SideNav
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isContractor={isContractor}
        onDashboardClick={handleDashboardClick} // Pass callback to SideNav
      />
      <AdministrationContent 
        key={dashboardKey} // Use key to force re-render
        isCollapsed={isCollapsed}
        isContractor={isContractor}
      />
    </div>
  );
};

export default MainLayout;
