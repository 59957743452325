// src/Shared/tradeDecorator.js
import { fetchTrades } from '../services/tradesService'; // Adjust the import path as needed

let tradeColorsCache = null;

export const loadTradeColors = async () => {
  if (tradeColorsCache) return tradeColorsCache; // Use cached data if available

  try {
    const trades = await fetchTrades(); // Assuming fetchTrades retrieves all trades
    tradeColorsCache = trades.reduce((acc, trade) => {
      acc[trade.tradename] = {
        textColour: trade.textColour || '#000000',
        backgroundColour: trade.backgroundColour || '#FFFFFF',
      };
      return acc;
    }, {});
    return tradeColorsCache;
  } catch (error) {
    console.error("Failed to load trades:", error);
    return {};
  }
};

export const tradeDecorator = (tradename) => {
  if (!tradeColorsCache) {
    console.warn("Trade colors not loaded yet.");
    return <span style={{ color: '#888' }}>{tradename || 'No Trade'}</span>;
  }

  const { textColour, backgroundColour } = tradeColorsCache[tradename] || {
    textColour: '#000000',
    backgroundColour: '#FFFFFF',
  };

  return (
    <div
      style={{
        padding: '2px 10px',
        backgroundColor: backgroundColour,
        color: textColour,
        display: 'inline-block',
        textAlign: 'center',
        border: '1px solid #ddd',
        borderRadius: '12px',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        lineHeight: '1.5',
        fontWeight: 'strong',
      }}
    >
      {tradename || 'No Trade'}
    </div>
  );
};
