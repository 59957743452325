import React from "react";
import styles from "../styles/Notifications.module.css";

const Notifications = ({ notifications }) => {
  return (
    <div className={styles.notificationsContainer}>
      <h3 className={styles.notificationsTitle}>Notifications</h3>
      <ul className={styles.notificationsList}>
        {notifications.map((notification, index) => (
          <li key={index} className={styles.notificationItem}>
            <p className={styles.notificationMessage}>{notification.message}</p>
            <span className={styles.notificationTime}>{notification.time}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notifications;
