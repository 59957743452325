import React, { useState, useEffect } from "react";
import { fetchJobById, fetchPropertyById,fetchWorkHistoryByPropertyId } from "../services/dbService";
import { STATUS_MAPPING } from "../services/dbService";
import styles from "./JobDetailForm.module.css";
import { titleDecorator } from '../Shared/titleDecorator';

const JobDetailForm = ({ jobId }) => {
  const [job, setJob] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyLocation, setPropertyLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("propertyImages");
  const [propertyFloorplans, setPropertyFloorplans] = useState([]);
  const [propertyWorks, setPropertyWorks] = useState([]);
  const titleBackgroundColour = 'var(--cmh-title-soft-background-color)'
  const googleMapsApiKey = "AIzaSyAftNcYYOuB3c3oHs4ZqVD6E8V5U10G63M";

  useEffect(() => {
    const loadJobAndProperty = async () => {
      try {
        const fetchedJob = await fetchJobById(jobId);
        setJob({ ...fetchedJob }); // Ensure a new reference is set
        if (fetchedJob?.propertyId) {
          const property = await fetchPropertyById(fetchedJob.propertyId);
          setPropertyImages(property?.propertyImages || []);
          setPropertyFloorplans(property?.propertyFloorplans || []); // Extract floorplans
          setPropertyLocation(`${property?.address || ""}, ${property?.postcode || ""}`.trim());
        }
      } catch (err) {
        console.error("Error loading job or property:", err);
        setError("Failed to load job details. Please try again later.");
      } finally {
        setLoading(false); // Ensure loading is always set to false
      }
    };
    loadJobAndProperty();
  }, [jobId]);
  
  useEffect(() => {
    const loadWorkHistory = async () => {
      try {
        const works = await fetchWorkHistoryByPropertyId(job?.propertyId || ""); // Fallback if job is null
        console.log("Fetched Work History:", works); // Debug log
        setPropertyWorks(works);
      } catch (error) {
        console.error("Failed to load work history:", error);
      }
    };
  
    loadWorkHistory();
  }, [job]); // Trigger whenever `job` changes
  
  if (loading) return <p>Loading job details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
    <h2>{job.jobId}: Job Detail</h2>

    <div className={styles.jobDetailContainer}>
      <form>
        <label htmlFor="jobDescription">{titleDecorator("Job Description:", titleBackgroundColour)}</label>
        <textarea id="jobDescription" name="jobDescription" rows="2" value={job?.jobDetail || ""} readOnly></textarea>
        <label htmlFor="furtherInformation">{titleDecorator("Further Information:", titleBackgroundColour)}</label>
        <textarea id="furtherInformation" name="furtherInformation" rows="2" value={job?.furtherDetails || ""} readOnly></textarea>
        <div className={styles.jobDetailRow}>
          <div className={styles.formGroup}>
            <label htmlFor="category">{titleDecorator("Category", titleBackgroundColour)}</label>
            <div id="category">
              <input type="text" id="category" name="category" value={job?.tradeName || ""} readOnly />
            </div>

          </div>
          <div className={styles.formGroup}>
            <label htmlFor="createdDate">{titleDecorator("Created", titleBackgroundColour)}</label>
            <input type="text" id="createdDate" name="createdDate" value={job?.createdAt ? new Date(job.createdAt.seconds * 1000).toLocaleDateString("en-GB") : ""} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="preQuotedWork">{titleDecorator("Pre-Quoted Work", titleBackgroundColour)}</label>
            <input type="text" id="preQuotedWork" name="preQuotedWork" value={job?.isPQW ? "Yes" : "No"} readOnly />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="status">{titleDecorator("Status", titleBackgroundColour)}</label>
            <input type="text" id="status" name="status" value={STATUS_MAPPING[job?.status]?.label || STATUS_MAPPING.default.label} readOnly />
          </div>
        </div>
      </form>

      <div className={styles.tabs}>
        <button className={`${styles.tabButton} ${activeTab === "propertyImages" ? styles.active : ""}`} onClick={() => setActiveTab("propertyImages")}>Property Images</button>
        <button className={`${styles.tabButton} ${activeTab === "propertyLocation" ? styles.active : ""}`} onClick={() => setActiveTab("propertyLocation")}>Location</button>
        {propertyFloorplans.length > 0 && (
          <button className={`${styles.tabButton} ${activeTab === "floorplan" ? styles.active : ""}`} onClick={() => setActiveTab("floorplan")}>Floorplan</button>
        )}
        {/* <button className={`${styles.tabButton} ${activeTab === "relationship" ? styles.active : ""}`} onClick={() => setActiveTab("relationship")}>Relationship</button> */}
      </div>

      {activeTab === "propertyImages" && (
        <div className={styles.propertyImagesSection}>
          <h3>Property Images</h3>
          <div className={styles.imageGrid}>
            {propertyImages.map((image, index) => (
              <div 
                key={index} 
                className={styles.imageContainer} 

                onClick={() => {
                  const screenWidth = window.innerWidth * 0.95;
                  const screenHeight = window.innerHeight * 0.95;
                
                  const imageHTML = `
                    <html>
                      <head>
                        <style>
                          html, body {
                            margin: 0;
                            padding: 0;
                            background: black;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                          }
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain; /* Ensures no cropping while filling space */
                          }
                        </style>
                      </head>
                      <body>
                        <img src="${image}" alt="Property Image">
                      </body>
                    </html>
                  `;
                
                  const newWindow = window.open(
                    '',
                    '_blank',
                    `width=${screenWidth},height=${screenHeight},top=${(window.innerHeight - screenHeight) / 2},left=${(window.innerWidth - screenWidth) / 2}`
                  );
                  newWindow.document.write(imageHTML);
                }}
                
                style={{ cursor: "pointer" }}
              >
                <img src={image} alt={`Property ${index + 1}`} className={styles.propertyImage} />
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "propertyLocation" && (
        <div className={styles.propertyLocationSection}>
          <h3>Property Location</h3>
          {propertyLocation ? (
            <iframe
              title="Property Location"
              src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${encodeURIComponent(propertyLocation)}`}
              width="100%"
              height="300"
              style={{ border: "none", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              allowFullScreen
              loading="lazy"
            ></iframe>
          ) : (
            <p>No location data available for this property.</p>
          )}
        </div>
      )}

      {activeTab === "floorplan" && (
        <div className={styles.floorplanSection}>
          <h3>Floorplan</h3>
          <div className={styles.imageGrid}>
            {propertyFloorplans.length > 0 ? (
              propertyFloorplans.map((url, index) => (
                <div 
                  key={index} 
                  className={styles.imageContainer} 
                  onClick={() => {
                    const width = Math.floor(window.innerWidth * 0.95);
                    const height = Math.floor(window.innerHeight * 0.95);
                    window.open(
                      url,
                      '_blank',
                      `width=${width},height=${height},top=${(window.innerHeight - height) / 2},left=${(window.innerWidth - width) / 2}`
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={url} alt={`Floorplan ${index + 1}`} className={styles.propertyImage} />
                </div>
              ))
            ) : (
              <p>No floorplans available for this property.</p>
            )}
          </div>
        </div>
      )}

{activeTab === "relationship" && (
  <div className={styles.relationshipSection}>
    <h3>Work History</h3>
    {propertyWorks.length > 0 ? (
      <ul className={styles.workList}>
        {propertyWorks.map((work) => (
          <li key={work.id} className={styles.workItem}>
            <p><strong>{work.jobDescription || "No description available"}</strong></p>
            <p>Date: {work.date ? new Date(work.date).toLocaleDateString("en-GB") : "No date available"}</p>
            <p>Status: {work.status || "No status available"}</p>
          </li>
        ))}
      </ul>
    ) : (
      <p>No work history found for this property.</p>
    )}
  </div>
)}

    </div>
    </>
  );
};

export default JobDetailForm;
