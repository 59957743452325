import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import JobTabsDetail from './JobTabsDetail';
import Comments from '../Comments/Comments';
import QuoteForm from '../QuoteForm/QuoteForm';
import PropertyDetail from '../PropertyInformation/PropertyDetail';
import PropertyDetailsPanel from '../PropertyDetailsPanel/PropertyDetailsPanel'; 
import { fetchPropertyById, updateJobStatus } from '../services/dbService';
import { assignJobInHouse, assignJobToContractor } from "../services/dbService";
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import '../styles/JobTabs.css';
import { notifyLandlord } from '../services/quoteService';
import { SystemCommenter} from '../Shared/SystemCommenter'


const JobTabs = ({ jobData, onReturnToDashboard, tab, isAdmin, tabModes }) => {
  const { user } = useAuthContext(); 
  const isLandlord = user?.role === 'Landlord';
  const isContractor = user?.role === 'Contractor';
  // const [activeTab, setActiveTab] = useState(tab || 'Detail');
  const [propertyDetails, setPropertyDetails] = useState('');
  const [isAssignable, setIsAssignable] = useState(false);

  useEffect(() => {
    if (jobData?.status === 2) {
      // alert("Job status is 2");
      setIsAssignable(true);
    }
  }, [jobData]);
  
  const handleAcceptQuote = async () => {
    try {
        // Step 2: Update job status to '2' (Accepted) after payment is confirmed
        await updateJobStatus(jobData.jobId, 2); // Status '2' represents an accepted quote.

        // Step 3: Notify the landlord or perform any other relevant actions
        notifyLandlord(jobData.landlordEmail, `Quote for Job ${jobData.jobId} has been accepted.`);
    
        // Step 4: Add system comment
        await SystemCommenter(
          jobData.jobId, 
          'Admin', 
          'The quote has been accepted for this job.'
        );
    
      // Step 5: Provide feedback and close the form
      // setFeedback('Quote accepted successfully!');
      onReturnToDashboard(); 
 
    } catch (error) {
      console.error('Error accepting the quote:', error);
      alert('Error accepting the quote: ' + error);
    }
  };

  const landlordData = {
    landlordFirstname: jobData.landlordFirstName,
    landlordLastname: jobData.landlordLastName,
    landlordEmail: jobData.landlordEmail,
  };
  
  useEffect(() => {
    const loadProperty = async () => {
      try {
        const propertyData = await fetchPropertyById(jobData.propertyId);
        if (propertyData) {
          setPropertyDetails(propertyData);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    loadProperty();
  }, [jobData.propertyId]);

  const visibleTabs = Object.keys(tabModes).filter(key => tabModes[key] !== 'hide');
  // const [activeTab, setActiveTab] = useState(tab || tabs[0]?.name || "Detail");
  const [activeTab, setActiveTab] = useState(tab || (tabModes.length > 0 ? tabModes[0].name : "Detail"));

  const tabs = [
    { name: 'Quote', component: <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord /> },
    { name: 'Detail', component: <JobTabsDetail jobId={jobData.jobId} readOnly={tabModes?.Detail === 'readOnly'} /> },
    { name: 'Comments', component: <Comments jobData={jobData} propertyDetails={propertyDetails} isActive={activeTab === 'Comments'} readOnly={tabModes?.Comments === 'readOnly'} /> },
    { name: 'Appointments', component: <AppointmentForm jobId={jobData.jobId} readOnly={tabModes?.Appointments === 'readOnly'} landlordData={landlordData} /> },
    { name: 'Property', component: <PropertyDetail propertyId={jobData.id} readOnly={tabModes?.Property === 'readOnly'} /> },
  ].filter(tab => visibleTabs.includes(tab.name));

  const handleContractorAcceptJob = async () => {
    // alert('handleContractorAcceptJob');

    const options = {
      jobId: jobData.jobId,
      contractorName: "One Love Painting & Decorating Ltd",
    };
    try {
      await assignJobToContractor(options);
      onReturnToDashboard();
    } catch (error) {
      alert(error.message);
    }
  };

  // const handleContractorSubmitAppointmentDate = async () => {
  //   try {
  //     await updateJobStatus(jobData.jobId, 4); // appointment sent
  //     onReturnToDashboard();
  //   } catch (error) {
  //     console.error('Error sending proposed appointment date: ', error);
  //     alert('Error sending proposed appointment date: ' + error);
  //   }
  // };

  const handleAssignInHouse = async () => {
    // alert('handleAssignInHouse');

    try {
      await assignJobInHouse(jobData.jobId);
      // alert("Job successfully assigned to In-House!");
      onReturnToDashboard();
    } catch (error) {
      alert(error.message);
    }
  };

  const handleRejectQuote = async () => {
    try {
      await updateJobStatus(jobData.jobId, -1);
      onReturnToDashboard();
    } catch (error) {
      console.error('Error rejecting the quote:', error);
      alert('Error-005: Failed to reject the quote. Please try again.');
    }
  };

  return (
    <div className="job-tabs-container">
      <PropertyDetailsPanel propertyDetails={propertyDetails} />
      
      <div className="job-tabs-button-group">
        <div className="tabs-left">
          {tabs.map(({ name }) => <button key={name} onClick={() => setActiveTab(name)} className={`job-tabs-button ${activeTab === name ? 'job-tabs-button-active' : ''}`}>{name}</button>)}
        </div>
        <div className="buttons-right">
          {(isAdmin || isLandlord || isContractor) && (
            <>
              {isLandlord && jobData.status === 1 && (
                <>
                  <button className="negative-button" onClick={handleRejectQuote}>Reject Quote</button>
                  <button className="positive-button" onClick={handleAcceptQuote}>Accept Quote and Pay</button>
                </>
              )}


              {isAssignable && isAdmin && !isContractor && (
                <button className="negative-button" onClick={handleAssignInHouse} style={{ marginLeft: 'auto' }}>Assign job in-house</button>
              )}
              
              {isContractor && jobData.status === 2 && (
                <button className="negative-button" onClick={handleContractorAcceptJob} style={{ marginLeft: 'auto' }}>Accept job</button>
              )}

              {/* {isContractor && jobData.status === 3 && (
                <button className="negative-button" onClick={handleContractorSubmitAppointmentDate} style={{ marginLeft: 'auto' }}>Submit Appointment date and time to Landlord</button>
              )} */}
              
              <button className="negative-button" onClick={onReturnToDashboard} style={{ marginLeft: 'auto' }}>Return to Dashboard</button>
              </>
          )}
        </div>
      </div>

      {/* Tab Content */}
      <div className="job-tabs-content">
        {tabs.find(({ name }) => name === activeTab)?.component || <p>Invalid tab selected</p>}
      </div>
    </div>
  );
};

export default JobTabs;
