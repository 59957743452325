import React, { Suspense } from 'react';
import { updateJobStatus } from '../services/jobService';
import '../styles/PopForm.css';

const forms = {
  QuoteForm: React.lazy(() => import('../QuoteForm/QuoteForm')),
  QuoteApproval: React.lazy(() => import('../QuoteForm/QuoteForm')), // Map QuoteApproval to QuoteForm
  JobRequest: React.lazy(() => import('../Job/JobRequestForm')), // Add JobRequest for requesting quotes
  DistributeForm: React.lazy(() => import('../Dashboard/DashboardDistribute')), // Admin  distribution form
  PreQuotedWork: React.lazy(() => import('../PreQuotedWork/PreQuotedWorkForm')), // Pre Quoted Works
  AppointmentForm: React.lazy(() => import('../AppointmentForm/AppointmentForm')), // Contractor Apponimemnt form
};

const PopForm = ({ formType, title, onClose, propertyId, agentId, jobData, readOnly, refreshJobs }) => {
  const SelectedForm = forms[formType] || null;

  // Handle approval actions
  const handleApproval = async (isApproved) => {
    const newStatus = isApproved ? 2 : -1; // Status 2 for Approve, -1 for Reject
    try {
      await updateJobStatus(jobData.jobId, newStatus);
      onClose(); // Close form after status update
    } catch (error) {
      console.error("Error updating job status:", error);
      alert("Error updating status. Please try again.");
    }
  };

  return (
    <div className="pop-form">
      <div className="pop-form-header">
        {/* CHANGE TITLE */}
        <h3>{title}</h3>
        <button className="close-btn" onClick={onClose}>x</button>
      </div>

      <Suspense fallback={<div>Loading {formType}...</div>}>
        {SelectedForm ? (
          <SelectedForm
            onClose={onClose}
            propertyId={propertyId}
            agentId={agentId}
            pqwData={formType === 'PreQuotedWork' ? jobData : null} // Pass `jobData` as `pqwData` only for `PreQuotedWork`
            jobData={formType !== 'PreQuotedWork' ? jobData : null} // Pass `jobData` for other forms
            readOnly={readOnly || formType === 'QuoteApproval'} // Default read-only for `QuoteApproval`
            refreshJobs={refreshJobs} // Pass refreshGrid to forms
          />
        ) : (
          <div>Form type "{formType}" not found.</div>
        )}
      </Suspense>

      {formType === 'QuoteApproval' && (
        <div className="approval-buttons">
          <button className="reject-btn" onClick={() => handleApproval(false)}>Reject Quote</button>
          <button className="approve-btn" onClick={() => handleApproval(true)}>Accept Quote</button>
        </div>
      )}
    </div>
  );
};

export default PopForm;
