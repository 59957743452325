// src/Panels/StartDateOffered.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
import TradeCellRenderer from '../Shared/TradeCellRenderer';

const StartDateOffered = ({ isSingleColumn, onRowClick }) => {
  const [offeredJobs, setOfferedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsOfferedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    {
      headerName: "TRADE",
      field: "tradeName", // Pass the tradeName as-is
      flex: 1.5,
      filter: true,
      cellRenderer: TradeCellRenderer, // Use TradeCellRenderer here
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "START DATE OFFERED", field: "startDateOffered", flex: 1.5,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadOfferedJobs = async () => {
      const options = { status: 4 };
      const jobs = await fetchJobs(options);
      setOfferedJobs(jobs);
    };

    loadOfferedJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Start Date Offered" // 4
      columnDefs={columnDefsOfferedJobs}
      rowData={offeredJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default StartDateOffered;
