import React, { useState, useEffect } from "react";
import { serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Firebase instances
import { updatePassword, sendSignInLinkToEmail } from 'firebase/auth';
import { updateDoc, doc, getDocs, collection } from 'firebase/firestore'; 
import { useNavigate } from 'react-router-dom';import styles from "./CPContractor.module.css";

const CPContractor = () => {
  
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const [trades, setTrades] = useState([]); // Holds trades collection data
  const navigate = useNavigate();
  
  // Fetch trade names and IDs from Firestore
  useEffect(() => {
    const fetchTrades = async () => {
      try {
        const tradeSnapshot = await getDocs(collection(db, "trades"));
        const tradesList = tradeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTrades(tradesList);
      } catch (error) {
        console.error("Error fetching trades:", error);
        setError("Error fetching trades");

      }
    };

    fetchTrades();
  }, []);

  // Extract email from URL params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = urlParams.get("email");
    if (userEmail) {
      setFormData((prev) => ({ ...prev, email: userEmail }));
    } else {
      console.error("No email found in URL parameters.");
      setError("Email not found in URL parameters.");
      navigate("/login");
    }
  }, [navigate]);

  // Validation for form submission
  const isFormValid =
    formData.firstName &&
    formData.lastName &&
    formData.mobileNumber &&
    formData.companyName &&
    formData.companyAddress &&
    formData.companyPostcode &&
    formData.officePhoneNumber &&
    formData.paymentBankName &&
    formData.paymentAccountNumber &&
    formData.paymentSortCode &&
    formData.tradesName &&
    formData.agreeToTerms;

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Special handling for sort code formatting
    let formattedValue = value;
    if (name === "paymentSortCode") {
      // Remove invalid characters (allow only digits and hyphens)
      const sanitizedValue = value.replace(/[^0-9-]/g, '');
  
      // Extract digits only for validation
      const digitsOnly = sanitizedValue.replace(/-/g, '').slice(0, 6);
  
      // Dynamically add hyphens based on input length
      formattedValue = digitsOnly
        .split('')
        .reduce((acc, char, idx) => {
          if (idx === 2 || idx === 4) {
            return acc + '-' + char; // Insert hyphens after 2nd and 4th digits
          }
          return acc + char;
        }, '');
  
      // Ensure the field is limited to 8 characters (XX-XX-XX)
      formattedValue = formattedValue.slice(0, 8);
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : formattedValue,
    }));
  };

  // Handle trade selection to set tradeId
  const handleTradeChange = (e) => {
    const selectedTrade = trades.find((trade) => trade.tradename === e.target.value);
    setFormData((prev) => ({
      ...prev,
      tradesName: e.target.value,
      tradesId: selectedTrade?.id || null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);
  
    try {
      // Update password in Firebase Auth
      if (formData.password) {
        try {
          await updatePassword(auth.currentUser, formData.password);
        } catch (error) {
          if (error.code === 'auth/requires-recent-login') {
            alert('Your session has expired. A new login link will be sent to your email.');
  
            // Resend login link for re-authentication
            const actionCodeSettings = {
              url: `https://completemaintenance.uk/complete-profile?email=${encodeURIComponent(formData.email)}`,
              handleCodeInApp: true,
            };
            try {
              await sendSignInLinkToEmail(auth, formData.email, actionCodeSettings);
              alert('A new login link has been sent to your email.');
            } catch (resendError) {
              console.error('Error resending login link:', resendError);
              setError('Failed to resend login link. Please contact support.');
            }
            return; // Stop further processing
          } else {
            setError('Failed to update password. Please try again.');
            return;
          }
        } finally {
          setIsSubmitting(false); // Stop spinner
        }
      }
  
      // Update Firestore with profile info
      const userDocRef = doc(db, "users", formData.email);
      await updateDoc(userDocRef, {
        companyCity: formData.companyCity,
        companyAddress: formData.companyAddress,
        companyName: formData.companyName,
        companyPostcode: formData.companyPostcode,
        firstName: formData.firstName,
        lastName: formData.lastName,
        officePhoneNumber: formData.officePhoneNumber,
        mobileNumber: formData.mobileNumber,
        paymentAccountNumber: formData.paymentAccountNumber,
        paymentBankName: formData.paymentBankName,
        paymentSortCode: formData.paymentSortCode,
        tradesId: formData.tradesId,
        tradesName: formData.tradesName,
        isProfileComplete: true,
        lastLoggedIn: serverTimestamp(),
      });
  
      setSuccess("Profile updated successfully!");
      navigate("/contractor-dashboard");
    } catch (error) {
      console.error("Error during profile update:", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setIsSubmitting(false); // Ensure spinner stops in case of an error
    }
  };
  

  return (
    <div className={styles.formContainer}>
      <div className={styles.brandingHeaderPanel}>
        <img src="/mmg-logo.png" alt="Logo" className={styles.logo} />
        <span>Complete Maintenance Hub</span>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Personal Information */}
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Personal Information</legend>
          <div className={styles.row}>
            <div>
              <label className={styles.label}>First Name</label>
              <input type="text" className={styles.input} value={formData.firstName || ""} name="firstName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Last Name</label>
              <input type="text" className={styles.input} value={formData.lastName || ""} name="lastName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Mobile Number</label>
              <input type="text" className={styles.input} value={formData.mobileNumber || ""} name="mobileNumber" onChange={handleChange} required />
            </div>
          </div>

          <div className={styles.row}>
            <div>
              <label className={styles.label}>Email Address</label>
              <input type="email" className={styles.input} value={formData.email || ""} name="email" readOnly />
            </div>
            <div>
              <label className={styles.label}>Password</label>
              <input type="password" className={styles.input} value={formData.password || ""} name="password" onChange={handleChange} required />
            </div>
          </div>

        </fieldset>

        {/* Company Information */}
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Company Information</legend>
          <div className={styles.row}>
            <div>
              <label className={styles.label}>Company Name</label>
              <input type="text" className={styles.input} value={formData.companyName || ""} name="companyName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Company Address</label>
              <input type="text" className={styles.input} value={formData.companyAddress || ""} name="companyAddress" onChange={handleChange} required />
            </div>

            <div>
              <label className={styles.label}>Town / City</label>
              <input type="text" className={styles.input} value={formData.companyCity || ""} name="companyCity" onChange={handleChange} required />
            </div>


            <div>
              <label className={styles.label}>Company Postcode</label>
              <input type="text" className={styles.input} value={formData.companyPostcode || ""} name="companyPostcode" onChange={handleChange} required />
            </div>

          </div>


          <div className={styles.row}>
            <div>
              <label className={styles.label}>Office Phone Number</label>
              <input type="text" className={styles.input} value={formData.officePhoneNumber || ""} name="officePhoneNumber" onChange={handleChange} required />
            </div>
          </div>


          <div className={styles.row}>
            <div>
              <label className={styles.label}>Bank Name</label>
              <input type="text" className={styles.input} value={formData.paymentBankName || ""} name="paymentBankName" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Account Number</label>
              <input type="text" className={styles.input} value={formData.paymentAccountNumber || ""} name="paymentAccountNumber" onChange={handleChange} required />
            </div>
            <div>
              <label className={styles.label}>Sort Code</label>
              <input type="text" className={styles.input} value={formData.paymentSortCode || ""} name="paymentSortCode" onChange={handleChange} required />
            </div>
          </div>
        </fieldset>

        {/* Trade Information */}
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Trade Information</legend>
          <div className={styles.row}>
            <div>
              <label className={styles.label}>Trade Name</label>
              <select className={styles.input} name="tradesName" value={formData.tradesName || ""} onChange={handleTradeChange} required>
                <option value="">Select Trade</option>
                {trades.map((trade) => (
                  <option key={trade.id} value={trade.tradename}>
                    {trade.tradename}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </fieldset>

        {/* Terms and Conditions */}
        <div className={styles.termsContainer}>
          <input type="checkbox" id="agreeToTerms" name="agreeToTerms" checked={formData.agreeToTerms || false} onChange={(e) => setFormData((prev) => ({ ...prev, agreeToTerms: e.target.checked }))} required />
          <label htmlFor="agreeToTerms" className={styles.termsLabel}>
            I agree to the <button type="button" onClick={() => window.open('/terms-and-conditions.html', '_blank', 'width=800,height=600')}>Terms and Conditions</button>
          </label>
        </div>

        {/* Submit Button */}
        <div className={styles.submitContainer}>
          <button type="submit" className={styles.submitButton} disabled={!isFormValid || isSubmitting} style={{ opacity: !isFormValid ? 0.5 : 1, cursor: !isFormValid ? "not-allowed" : "pointer" }}>
            Complete Registration
          </button>
        </div>
        <div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{success}</p>}
        </div>

      </form>
    </div>
  );
};

export default CPContractor;
