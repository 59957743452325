import React from 'react';
import AgentPropertiesSubmitted from './AgentPropertiesSubmitted';
import JobsAccepted from './AgentJobsAccepted';
import JobsCompleted from './AgentJobsCompleted';
import '../styles/AgentManageProperties.css';

const ManageProperties = ({ agentData, onRowSelectAction }) => {

  const handleRowClick = (jobData, tabModes) => {
    onRowSelectAction(jobData.data, tabModes); // Forward jobData and tabModes
  };
  
  return (
    <div className="manage-properties-container">
      <div className="row">
        <div className="grid-section">
            <AgentPropertiesSubmitted  handleRowClick={handleRowClick} />
        </div>
        <div className="grid-section">
          <JobsAccepted handleRowClick={handleRowClick} />
        </div>

      </div>
      <div className="row full-width">
        <div className="grid-section">
          <JobsCompleted handleRowClick={handleRowClick} />
        </div>
      </div>
    </div>
  );
};

export default ManageProperties;
