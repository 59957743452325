import { SUBSCRIPTION_MAPPING } from '../services/dbService';

// Helper function to calculate date difference in minutes
const dateDiff = (endDate, startDate) => {
  return (endDate - startDate) / (1000 * 60); // Returns difference in minutes
};

// Helper function to get the weekend offset in days
const getWeekendAdjustment = (date) => {
  const dayOfWeek = date.getDay();
  let incrementByDays = 0;

  // If the day is Saturday (6) or Sunday (0), adjust to Monday
  switch (dayOfWeek) {
    case 6: // Saturday
      // console.log('Saturday DETECTED');
      incrementByDays = 2; // Move to Monday
      break;
    case 0: // Sunday
      // console.log('Sunday DETECTED');
      incrementByDays = 1; // Move to Monday
      break;
    default:
      // console.log('NOTHING DETECTED: ' + dayOfWeek);
      incrementByDays = 0; // No change if it's a weekday    
  }

  return incrementByDays;
};


// Operational Time Remaining based on reference time
export const OperationalTimeRemaining = (referenceTime) => {
  const currentHour = parseInt(referenceTime.split('T')[1].split(':')[0], 10);
  const currentMinute = parseInt(referenceTime.split('T')[1].split(':')[1], 10);

  const businessCloseInMinutes = 17 * 60; // Business close time (17:00)
  const simulatedTimeInMinutes = currentHour * 60 + currentMinute;
  const remainingMinutes = businessCloseInMinutes - simulatedTimeInMinutes;

  return remainingMinutes > 0 ? remainingMinutes : 0;
};

// Helper function for calculating platinum window
export const calcPlatinumEndTime = (quotePaidAt, jobId) => {
  const quotePaidAtTime = new Date(quotePaidAt);
  const WindowTime =  SUBSCRIPTION_MAPPING["1"].releaseAfter;
  let platinumEndTime = new Date(quotePaidAtTime); // Start from the actual payment time

  // If the payment is before business start time (9 AM)
  if (quotePaidAtTime >= new Date(quotePaidAt).setHours(16, 0, 0, 0) && quotePaidAtTime < getBusinessEndDate(quotePaidAtTime)) {

    // alert('Case 1');
 

    // Check if the payment was made on Sunday
    const dayOfWeek = new Date(quotePaidAt).getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    if (dayOfWeek === 0 || dayOfWeek === 6) { 
      // console.log("Payment made on a weekend . Platinum ends at Monday 10:00 AM.");
      // Set platinum end time directly to Monday at 10:00 AM
      platinumEndTime.setHours(9, 0, 0, 0);
      platinumEndTime.setMinutes(platinumEndTime.getMinutes() + WindowTime);
    
    } else {
      // Calculate how many minutes past 4 PM
      const minutesPast4PM = dateDiff(quotePaidAtTime, new Date(quotePaidAt).setHours(16, 0, 0, 0)); 
      platinumEndTime.setDate(platinumEndTime.getDate() + 1); // Move to next day
      platinumEndTime.setHours(9, minutesPast4PM, 0, 0); // Set 9 AM next day + carry over minutes
      console.log('Case 1: ' + jobId + ' Paid at: ' + quotePaidAt);
      // console.log( "Payment is after 16:00 but before 17:00 - Platinum until next day business start, with minutes carried over");
    }

  } else if (quotePaidAtTime < getBusinessStartDate(quotePaidAt)) {


    // alert('Case 2');
    console.log('Case 2: ' + jobId + ' Paid at: ' + quotePaidAt);
        
    // Get the business start time and add the WindowTime
    const businessStartTimePlusWindow = new Date(getBusinessStartDate(quotePaidAt));
    businessStartTimePlusWindow.setMinutes(businessStartTimePlusWindow.getMinutes() + WindowTime);

    // Check if the payment time is before the business start time plus the platinum window duration
    if (quotePaidAtTime < businessStartTimePlusWindow) {
      // console.log("Payment is before business start time - Platinum until same day business start, with WindowTime duration added");

      // Set platinum end time to 9 AM the same day
      platinumEndTime.setHours(9, 0, 0, 0); // Set to 9 AM the same day
      platinumEndTime.setMinutes(platinumEndTime.getMinutes() + WindowTime); // Add platinum window duration
      
    } else {
      // If payment is after business start time, adjust platinumEndTime as needed
      // console.log("Payment is after business start time - Platinum end time calculated based on payment time");

      platinumEndTime.setHours(9, 0, 0, 0); // Reset to 9 AM the next day
      platinumEndTime.setMinutes(platinumEndTime.getMinutes() + WindowTime); // Add platinum window duration
    }

  } else if (quotePaidAtTime >= getBusinessEndDate(quotePaidAtTime) && quotePaidAtTime < new Date(getBusinessEndDate(quotePaidAtTime)).setHours(23, 59, 59, 999)) {
    // alert('Case 3');

    console.log('Case 3: ' + jobId + ' Paid at: ' + quotePaidAt);
    // console.log("Payment is after business end time but before end of the day - Platinum until next day business start, with WindowTime duration added");
  
    // Adjust platinumEndTime for next day business start
    platinumEndTime.setDate(platinumEndTime.getDate() + 1); // Move to the next day
    platinumEndTime.setHours(9, 0, 0, 0); // Set to 9 AM the next day
    platinumEndTime.setMinutes(platinumEndTime.getMinutes() + WindowTime); // Add platinum window duration

  } else if (quotePaidAtTime >= new Date(getBusinessEndDate(quotePaidAtTime)).setHours(23, 59, 59, 999)) {
    // alert('Case 4');

    console.log('Case 4: ' + jobId + ' Paid at: ' + quotePaidAt);
    // console.log("Payment is after midnight - Platinum until next business day start, with WindowTime duration added");
  
    // Adjust platinumEndTime for next day business start
    platinumEndTime.setDate(platinumEndTime.getDate() + 1); // Move to the next day
    platinumEndTime.setHours(9, 0, 0, 0); // Set to 9 AM the next day
    platinumEndTime.setMinutes(platinumEndTime.getMinutes() + WindowTime); // Add platinum window duration
    
  } else {
    // alert('Case 5');
    console.log('Case 5: ' + jobId + ' Paid at: ' + quotePaidAt);
    // console.log("Normal case, within business hours");  

    // Check if Saturday or Sunday
    const dayOfWeek = quotePaidAt.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {  // Saturday (6) or Sunday (0)
      // console.log('WEEKEND DETECTED ' + dayOfWeek);

      // Adjust platinumEndTime to 9 AM
      platinumEndTime.setHours(9, 0, 0, 0);
    } 
    // Add the WindowTime in minutes, regardless of weekend or weekday
    platinumEndTime.setMinutes(platinumEndTime.getMinutes() + WindowTime); // Add platinum window duration

  }
  // Adjust platinumEndTime for Weekend adjustment
  platinumEndTime.setDate(platinumEndTime.getDate() + getWeekendAdjustment(platinumEndTime)); 
  console.log('Platinum until ' + platinumEndTime);
  return platinumEndTime;
};

export const getBusinessStartDate = (incomingDate) => {
  const businessStart = new Date(incomingDate);
  businessStart.setHours(9, 0, 0, 0); // Start of business day at 9 AM
  return businessStart;
};

export const getBusinessEndDate = (incomingDate) => {
  const businessEnd = new Date(incomingDate);
  businessEnd.setHours(17, 0, 0, 0); // Start of business day at 9 AM
  return businessEnd;
};

// Job Visibility Calculation, returning platinum status and end time
export const calculateJobVisibility = (quotePaidAt, referenceTime) => {
  const businessStart = getBusinessStartDate(quotePaidAt);
  const businessEnd = getBusinessEndDate(quotePaidAt);

  // Use dateDiff to calculate time difference between quotePaidAt and business start/end times
  const timeBeforeBusinessStart = dateDiff(businessStart, new Date(quotePaidAt)); // In minutes
  const timeAfterBusinessEnd = dateDiff(new Date(quotePaidAt), businessEnd); // In minutes

  // Calculate platinum end time using the new method
  const platinumEndTime = calcPlatinumEndTime(quotePaidAt);  
  const operationalRemaining = OperationalTimeRemaining(referenceTime); // Remaining minutes until business close
  
  let inBounds = false;
  
  // Logic to determine if the job is in bounds (within platinum window)
  if (new Date(quotePaidAt) >= new Date(referenceTime)) {
    inBounds = true; // Job is still within the platinum window
  } else if (operationalRemaining > 0) {
    inBounds = true; // Job is within bounds if operational time remaining is still positive
  }

  return {
    isPlatinum: inBounds,
    platinumEndTime: platinumEndTime,
    operationalRemaining: operationalRemaining,
    timeBeforeBusinessStart,
    timeAfterBusinessEnd
  };
};

export const isPlatinumWindowActive = (platinumEndTime) => {
  const currentTimeDate = new Date();  
  // Add the releaseAfter time to platinumEndTime to get the correct end of the platinum window
  const platinumEndWithWindow = new Date(platinumEndTime);
  // platinumEndWithWindow.setMinutes(platinumEndWithWindow.getMinutes() + SUBSCRIPTION_MAPPING["1"].releaseAfter); // Adding releaseAfter minutes
  // Return whether the current time is before the adjusted platinum end time
  return platinumEndWithWindow > currentTimeDate;
};

